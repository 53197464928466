import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useJob } from "../../context/JobContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import JobForm from "./JobForm";

export default function AddJob() {
    const { createJob } = useJob();

    const [job, setJob] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const jobContentEditorRef = React.useRef();
    const navigate = useNavigate();

    const [image, setImage] = React.useState(null);
    const slugRef = React.useRef();

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/jobs");
            }, 2000);
        }
    });

    const submitForm = async (e) => {
        e.preventDefault();

        const description = jobContentEditorRef.current.getContent();

        let createObject = { ...job, description };
        delete createObject._id;
        delete createObject.updatedAt;
        delete createObject.createdAt;

        createObject = {
            ...createObject,
            noOfVacancies: createObject?.noOfVacancies?.toString(),
            // active: createObject?.active.toLowerCase() === "true" || true,
            slug: createObject.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "job",
            });

            createObject = response?.key && {
                ...createObject,
                featuredImage: response.key,
            };
        }

        const result = await createJob(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <JobForm
                job={job}
                onClick={submitForm}
                submitButtonText="Create"
                sectionHeader="Add Job"
                slugRef={slugRef}
                jobContentEditorRef={jobContentEditorRef}
                onTitleChange={(e) => {
                    setJob({ ...job, title: e.target.value });
                }}
                onSlugChange={(e) => {
                    setJob({ ...job, slug: e.target.value });
                }}
                onDeadlineChange={(e) => {
                    setJob({ ...job, deadline: e.target.value });
                }}
                onLocationChange={(e) => {
                    setJob({ ...job, nature: e.target.value });
                }}
                onTypeChange={(e) => {
                    setJob({ ...job, type: e.target.value });
                }}
                onExperienceChange={(e) => {
                    setJob({ ...job, experience: e.target.value });
                }}
                onNoOfVacanciesChange={(e) => {
                    setJob({ ...job, noOfVacancies: e.target.value });
                }}
                onActiveChange={(e) => {
                    setJob({ ...job, active: e.target.value });
                }}
                onFeaturedImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanFeaturedImage={() => {
                    setJob({ ...job, featuredImage: null });
                }}
                onDiscard={() => {
                    setTimeout(() => {
                        navigate(-1);
                    }, 500);
                }}
            />
        </DashboardLayout>
    );
}
