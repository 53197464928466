import axios from "axios";
import { PRODUCT } from "../../lib/slug";

export const list = async (token) => {
    try {
        const response = await axios.get(PRODUCT + "/all", {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
