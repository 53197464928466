import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useTestimonial } from "../../context/TestimonialContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import TestimonialForm from "./Form";

export default function AddTestimonial() {
    const navigate = useNavigate();
    const { createTestimonial } = useTestimonial();

    const [testimonial, setTestimonial] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [clientImage, setClientImage] = React.useState(null);
    const [clientCompanyLogo, setClientCompanyLogo] = React.useState(null);

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        let createObject = {
            ...testimonial,
        };

        if (clientImage?.file) {
            const response = await imageHandler.uploadV2({
                image: clientImage,
                folder: "testimonial",
            });

            createObject = response?.key && {
                ...createObject,
                clientImage: response.key,
            };
        }

        if (clientCompanyLogo?.file) {
            const response = await imageHandler.uploadV2({
                image: clientCompanyLogo,
                folder: "testimonial",
            });

            createObject = response?.key && {
                ...createObject,
                clientCompanyLogo: response.key,
            };
        }

        const result = await createTestimonial(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/testimonials");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="Add Case Studies" />

            <TestimonialForm
                testimonial={testimonial}
                onClientNameChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientName: e.target.value,
                    });
                }}
                onClientTitleChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientTitle: e.target.value,
                    });
                }}
                onClientCommentChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientComment: e.target.value,
                    });
                }}
                onPositionChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        position: parseInt(e.target.value),
                    });
                }}
                onClientImageChange={(image, name) => {
                    setClientImage({ file: image, name: name });
                }}
                cleanClientImage={() => {
                    setTestimonial({ ...testimonial, clientImage: null });
                }}
                onClientCompanyLogoChange={(image, name) => {
                    setClientCompanyLogo({ file: image, name: name });
                }}
                cleanClientCompanyLogo={() => {
                    setTestimonial({ ...testimonial, clientCompanyLogo: null });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/testimonials")}
            />
        </DashboardLayout>
    );
}
