import jsCookie from "js-cookie";
import React from "react";
import { accessLogHandler } from "../handler/accessLogList";
const AccessLogContext = React.createContext();

export const useAccessLog = () => React.useContext(AccessLogContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export default function AccessLogProvider({ children }) {
    const [accessLogs, setAccessLogs] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [pageList, setPageList] = React.useState([]);

    const getAccessLogList = React.useCallback(async (page, limit = 10) => {
        const result = await accessLogHandler.list(token, {
            page: page,
            limit: limit,
        });

        setAccessLogs(result.data);
        setTotalPages(result.totalPage);
        setCurrentPage(parseInt(result.page));
        setPageList(Array.from({ length: result.totalPage }, (v, k) => k + 1));

        return result;
    }, []);

    const contextValue = {
        accessLogs,
        getAccessLogList,
        currentPage,
        totalPages,
        pageList,
    };
    return (
        <AccessLogContext.Provider value={contextValue}>
            {children}
        </AccessLogContext.Provider>
    );
}
