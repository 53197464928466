import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import React from "react";
import logout from "../../handler/logout";
import { delayRedirect } from "../../utils";

export default function Logout() {
    return (
        <div className="flex-shrink-0 flex bg-gray-700 p-4">
            <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                    logout();
                    delayRedirect(0, "/login");
                }}
            >
                <ArrowLeftOnRectangleIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                />
                Logout
            </button>
        </div>
    );
}
