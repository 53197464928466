import { classNames } from "../../utils";

export default function FullWidthWithHeaderCard({ headerItems, body, noBg }) {
    return (
        <div
            className={classNames(
                !noBg && "bg-white shadow rounded-lg divide-y divide-gray-200",
                "overflow-hidden"
            )}
        >
            <div className="px-4 py-5 sm:px-6">
                {/* Content goes here */}
                {/* We use less vertical padding on card headers on desktop than on body sections */}
                {headerItems}
            </div>
            <div className="px-4 py-5 sm:p-6">{body}</div>
        </div>
    );
}
