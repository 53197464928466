const {
    PencilIcon,
    IdentificationIcon,
} = require("@heroicons/react/24/outline");
const { default: EditRole } = require("../../pages/Role/Edit");
const { default: AddRole } = require("../../pages/Role/AddRole");
const { default: Roles } = require("../../pages/Role");

module.exports = (Permission) => [
    {
        name: "Roles",
        // href: "/roles",
        icon: IdentificationIcon,
        current: false,
        component: Roles,
        hidden: true,
        permissions: [Permission.READ_ALL_ROLES],
        children: [
            {
                name: "Add",
                href: "/roles/add",
                icon: PencilIcon,
                current: false,
                parent: "Roles",
                hidden: true,

                component: AddRole,
                permissions: [Permission.CREATE_ROLE],
            },
            {
                name: "Edit",
                href: "/roles/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Roles",
                component: EditRole,
                hidden: true,
                permissions: [Permission.UPDATE_ROLE],
            },
        ],
    },
];
