import axios from "axios";
import { BLOG_CATEGORY } from "../../lib/slug";

export const get = async (token, id) => {
    try {
        const response = await axios.get(BLOG_CATEGORY, {
            params: {
                id: id,
            },

            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
