import { classNames } from "../../utils";

export default function TextareaInput({
    htmlFor,
    placeholder,
    onChange = () => {},
    onBlur = () => {},
    value = "",
    rows,
    reference,
    disabled = false,
    className = "",
}) {
    return (
        <>
            <textarea
                disabled={disabled}
                rows={rows ? rows : 1}
                name={htmlFor}
                id={htmlFor}
                ref={reference}
                className={classNames(
                    "block w-full border-0 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm",
                    className
                )}
                placeholder={placeholder}
                defaultValue={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        </>
    );
}
