import { create } from "./create";
import { deleteBlog } from "./delete";
import { get } from "./get";
import { info } from "./info";
import { list } from "./list";
import { publish } from "./publish";
import { update } from "./udpate";
import { unpublish } from "./unpublish";

export const blogHandler = {
    create,
    list,
    get,
    update,
    delete: deleteBlog,
    publish,
    unpublish,
    info,
};
