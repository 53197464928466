import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../../components/Notification/Toast";
import { useBlogCategory } from "../../../context/BlogCategoryContext";
import { imageHandler } from "../../../handler/image";
import DashboardLayout from "../../../layouts/Dashboard";
import Form from "./Form";

export default function EditBlogCategories() {
    const { getBlogCategoryById, updateBlogCategory } = useBlogCategory();
    const { id } = useParams();

    const [blogCategory, setBlogCategory] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const blogCategoryExcerptEditorRef = React.useRef();
    const navigate = useNavigate();

    const slugRef = React.useRef();

    React.useEffect(() => {
        (async () => {
            const blogRes = await getBlogCategoryById(id);

            setBlogCategory(blogRes.data[0]);
        })();
    }, [getBlogCategoryById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/blogs/categories");
            }, 2000);
        }
    });

    const submitForm = async (e) => {
        e.preventDefault();

        const excerpt = blogCategoryExcerptEditorRef.current.getContent();

        let updateObject = { ...blogCategory, excerpt };
        delete updateObject._id;
        delete updateObject.updatedAt;
        delete updateObject.createdAt;
        delete updateObject.count;
        delete updateObject.editors;
        delete updateObject.lastEditedBy;
        delete updateObject.createdBy;
        delete updateObject.__v;

        updateObject = {
            ...updateObject,
            slug: updateObject.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "blogCategory",
            });

            updateObject = response?.key && {
                ...updateObject,
                featuredImage: response.key,
            };
        }

        const result = await updateBlogCategory(blogCategory._id, updateObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {blogCategory && (
                <Form
                    blogCategory={blogCategory}
                    onClick={submitForm}
                    submitButtonText="Update"
                    sectionHeader="Edit Category"
                    blogCategoryExcerptEditorRef={blogCategoryExcerptEditorRef}
                    onNameChange={(e) => {
                        setBlogCategory({
                            ...blogCategory,
                            name: e.target.value,
                        });
                    }}
                    onSlugChange={(e) => {
                        setBlogCategory({
                            ...blogCategory,
                            slug: e.target.value,
                        });
                    }}
                    onFeaturedImageChange={(image, name) => {
                        setImage({ file: image, name: name });
                    }}
                    cleanFeaturedImage={() => {
                        setBlogCategory({
                            ...blogCategory,
                            featuredImage: null,
                        });
                    }}
                    onDiscard={() => {
                        setTimeout(() => {
                            navigate(-1);
                        }, 500);
                    }}
                />
            )}
        </DashboardLayout>
    );
}
