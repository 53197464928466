import { changePassword } from "./changePassword";
import { create } from "./create";
import { deleteUser } from "./delete";
import { get } from "./get";
import { getProfile } from "./getProfile";
import { list } from "./list";
import { update } from "./update";
import { updateProfile } from "./updateProfile";

export const userHandler = {
    getProfile,
    get,
    create,
    list,
    update,
    delete: deleteUser,
    changePassword,
    updateProfile,
};
