import React from "react";
import BlockCenteredModal from "./Modal/BlockCenter";

export default function Loading() {
    return (
        <BlockCenteredModal>
            <p>Loading ...</p>
        </BlockCenteredModal>
    );
}
