import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexBasisEven from "../../components/Design/OneLiner/FlexBasisEven";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import SimpleDropdown from "../../components/Dropdown/Simple";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import TinyMCEditor from "../../components/TinyMCEditor";
import { IMAGES } from "../../lib/slug";
import { generateImageURL, getSlugFromSentence } from "../../utils";

export default function BlogForm({
    blog,
    onClick,
    onTitleChange,
    onSlugChange,
    onTagsChange,
    onFeaturedImageChange,
    onCategoryChange,
    categories,
    selectedCategory,
    cleanFeaturedImage,
    slugRef,
    submitButtonText,
    blogExcerptEditorRef,
    sectionHeader,
    blogContentEditorRef,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="title"
                                label="Title"
                                htmlFor="title"
                                showLabel={true}
                                ariaDescribeBy="title-helper-text"
                                hint="Title of the blog"
                            >
                                <TextareaInput
                                    htmlFor="title"
                                    value={blog?.title || ""}
                                    onChange={onTitleChange}
                                    placeholder="Enter title"
                                />
                            </LabelAndHelperWrapper>
                            <LabelAndHelperWrapper
                                label="Featured Image"
                                htmlFor="featured-image"
                                showLabel={true}
                                ariaDescribeBy="featured-image-helper-text"
                                hint="This will be used as the featured image for the blog"
                            >
                                <ImageInput
                                    getData={onFeaturedImageChange}
                                    image={
                                        blog?.featuredImage &&
                                        generateImageURL(
                                            IMAGES,
                                            blog.featuredImage
                                        )
                                    }
                                    cleanImage={cleanFeaturedImage}
                                />
                            </LabelAndHelperWrapper>
                            <LabelAndHelperWrapper
                                label="Blog Content"
                                htmlFor="blog-content"
                                showLabel={true}
                            >
                                <TinyMCEditor
                                    editorRef={blogContentEditorRef}
                                    placeholder="Enter blog content here"
                                    initialValue={blog?.content || ""}
                                />
                            </LabelAndHelperWrapper>
                            <FlexBasisEven
                                kids={[
                                    <SimpleDropdown
                                        label="Category"
                                        defaultValue="Select category"
                                        options={categories}
                                        selected={selectedCategory}
                                        onSelect={onCategoryChange}
                                    />,
                                ]}
                            />

                            <LabelAndHelperWrapper
                                label="Tags"
                                htmlFor="tags"
                                showLabel={true}
                                ariaDescribeBy="tags-helper-text"
                                hint="Tags of the blog. Separate tags with comma"
                            >
                                <TextareaInput
                                    htmlFor="tags"
                                    onChange={onTagsChange}
                                    value={blog?.tags?.join(", ") || ""}
                                    reference={slugRef}
                                    placeholder="Enter tags"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Blog Excerpt"
                                htmlFor="blog-excerpt"
                                showLabel={true}
                                ariaDescribeBy="blog-except-helper-text"
                                hint="A very short description of the blog within 100 words"
                            >
                                <TinyMCEditor
                                    editorRef={blogExcerptEditorRef}
                                    initialValue={blog?.excerpt || ""}
                                    placeholder="Enter blog excerpt here"
                                    menubar=""
                                    toolbar=""
                                    plugins={["wordcount"]}
                                    height="120"
                                />
                            </LabelAndHelperWrapper>

                            {/* <FlexBasisEven
                                kids={
                                    [
                                        <LabelAndHelperWrapper
                                            key="tags"
                                            label="Tags"
                                            htmlFor="tag"
                                            showLabel={true}
                                            ariaDescribeBy="tag-helper-text"
                                            hint="Separate tags with commas"
                                        >
                                            <TextareaInput
                                                htmlFor="slug"
                                                reference={slugRef}
                                                placeholder="Enter tags"
                                            />
                                        </LabelAndHelperWrapper>,
                                    ]
                                }
                            /> */}

                            <LabelAndHelperWrapper
                                key="slug"
                                label="Slug"
                                htmlFor="slug"
                                showLabel={true}
                                ariaDescribeBy="slug-helper-text"
                                hint="This will be used in the URL"
                            >
                                <TextareaInput
                                    htmlFor="slug"
                                    onChange={onSlugChange}
                                    value={
                                        blog?.slug ||
                                        (blog &&
                                            blog.title &&
                                            getSlugFromSentence({
                                                sentence: blog?.title,
                                            })) ||
                                        ""
                                    }
                                    reference={slugRef}
                                    placeholder="Enter slug"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    {/* <TertiaryButton
                                        name="Save as draft"
                                        onClick={(e) => onClick(e, true)}
                                    /> */}
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
