export default function FlexBasisEven({ kids }) {
    return (
        <div className="flex flex-row gap-5">
            {kids.map((kid, index) => (
                <div key={index} className="basis-1/2">
                    {kid}
                </div>
            ))}
        </div>
    );
}
