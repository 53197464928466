import React, { useMemo } from "react";
import ContainerWithTitleAndButton from "../../components/Container/TitleAndButton";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import BasicPanel from "../../components/Panels/Basic";
import { formateDate } from "../../utils";
import { actions } from "../../utils/actions";

export default function AccessLog({ accessLogs, title, btnName, btnOnClick }) {
    const memoizedComponent = useMemo(
        () => (
            <div>
                {accessLogs && (
                    <ContainerWithTitleAndButton
                        title={title}
                        btnName={btnName}
                        btnOnClick={btnOnClick}
                    >
                        {accessLogs.map((log) => (
                            <BasicPanel
                                className="text-sm px-2 md:py-1"
                                key={log._id}
                            >
                                <FlexOneLineJustifyBetween>
                                    <p>
                                        {log.user?.name || "Public"}{" "}
                                        <span className="opacity-50">
                                            {actions[log.action].text}
                                        </span>
                                        , &nbsp;
                                        <span className="opacity-50">
                                            {log.resource || ""}
                                        </span>
                                        , &nbsp;
                                        <span className="opacity-60">
                                            {formateDate(log.createdAt)}
                                        </span>
                                        .
                                    </p>

                                    {actions[log.action].icon}
                                </FlexOneLineJustifyBetween>

                                {log.data && (
                                    <div className="mt-3 px-5 space-y-1">
                                        <p>Request payload,</p>
                                        <pre
                                            dangerouslySetInnerHTML={{
                                                __html: JSON.stringify(
                                                    log.data,
                                                    null,
                                                    2
                                                ),
                                            }}
                                        />
                                    </div>
                                )}
                            </BasicPanel>
                        ))}
                    </ContainerWithTitleAndButton>
                )}
            </div>
        ),
        [accessLogs, title, btnName, btnOnClick]
    );

    return memoizedComponent;
}
