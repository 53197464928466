import { Permission } from "./permissionStrings";

export const hasPermission = (loggedInUserPermissions, permissions) => {
    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    let missingPermissions = [];

    for (const permission of permissions) {
        if (!loggedInUserPermissions.includes(permission)) {
            missingPermissions.push(permission);
        }
    }

    return missingPermissions.length === 0 ? true : false;
};
