import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import { IMAGES } from "../../lib/slug";
import { generateImageURL } from "../../utils";

export default function TestimonialForm({
    testimonial,
    contentEditorRef,
    onClientNameChange,
    onClientTitleChange,
    onClick,
    onPositionChange,
    onClientImageChange,
    cleanClientImage,
    onClientCompanyLogoChange,
    cleanClientCompanyLogo,
    onClientCommentChange,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="clientName"
                                label="Client Name"
                                htmlFor="clientName"
                                showLabel={true}
                                ariaDescribeBy="clientName-helper-text"
                                hint="Client Name of the testimonial"
                            >
                                <TextareaInput
                                    htmlFor="clientName"
                                    value={testimonial?.clientName || ""}
                                    onChange={onClientNameChange}
                                    placeholder="Enter Client Name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Client Image"
                                htmlFor="client-image"
                                showLabel={true}
                                ariaDescribeBy="client-image-helper-text"
                                hint="This will be used as the client image for the testimonial"
                            >
                                <ImageInput
                                    getData={onClientImageChange}
                                    image={
                                        testimonial?.clientImage &&
                                        generateImageURL(
                                            IMAGES,
                                            testimonial.clientImage
                                        )
                                    }
                                    cleanImage={cleanClientImage}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Company Logo"
                                htmlFor="client-company-image"
                                showLabel={true}
                                ariaDescribeBy="client-company-image-helper-text"
                                hint="This will be used as the client company logo for the testimonial"
                            >
                                <ImageInput
                                    getData={onClientCompanyLogoChange}
                                    image={
                                        testimonial?.clientCompanyLogo &&
                                        generateImageURL(
                                            IMAGES,
                                            testimonial.clientCompanyLogo
                                        )
                                    }
                                    cleanImage={cleanClientCompanyLogo}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="clientTitle"
                                label="Client title"
                                htmlFor="clientTitle"
                                showLabel={true}
                                ariaDescribeBy="clientTitle-helper-text"
                                hint="Client title of the testimonial"
                            >
                                <TextareaInput
                                    htmlFor="clientTitle"
                                    value={testimonial?.clientTitle || ""}
                                    onChange={onClientTitleChange}
                                    placeholder="Enter client title"
                                />
                            </LabelAndHelperWrapper>

                            {/* <LabelAndHelperWrapper
                                key="position"
                                label="Position"
                                htmlFor="position"
                                showLabel={true}
                                ariaDescribeBy="position-helper-text"
                                hint="Position of the testimonial"
                            >
                                <TextareaInput
                                    htmlFor="position"
                                    value={testimonial?.position || ""}
                                    onChange={onPositionChange}
                                    placeholder="Enter position"
                                />
                            </LabelAndHelperWrapper> */}

                            <LabelAndHelperWrapper
                                key="clientComment"
                                label="Client comment"
                                htmlFor="clientComment"
                                showLabel={true}
                                ariaDescribeBy="clientComment-helper-text"
                                hint="Client comment of the testimonial"
                            >
                                <TextareaInput
                                    htmlFor="clientComment"
                                    value={testimonial?.clientComment || ""}
                                    onChange={onClientCommentChange}
                                    placeholder="Enter client Comment"
                                    rows={3}
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
