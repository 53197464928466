import { create } from "./create";
import { deleteBlog } from "./delete";
import { get } from "./get";
import { list } from "./list";
import { update } from "./udpate";

export const blogCategoryHandler = {
    create,
    list,
    get,
    update,
    delete: deleteBlog,
};
