import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useShortLink } from "../../context/ShortLinkContext";
import DashboardLayout from "../../layouts/Dashboard";
import { deletePropertiesFromObject } from "../../utils";
import ShortLinkForm from "./Form";

export default function EditShortLink() {
    const { getShortLinkById, updateShortLink } = useShortLink();
    const { id } = useParams();
    const navigate = useNavigate();

    const [shortLink, setShortLink] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const shortLinkResponse = await getShortLinkById(id);

            setShortLink(shortLinkResponse.shortlinks[0]);
        })();
    }, [getShortLinkById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/short-links");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        let updateObject = {
            ...shortLink,
        };

        const cleanObj = deletePropertiesFromObject(updateObject, [
            "__v",
            "createdAt",
            "updatedAt",
            "editors",
            "status",
            "lastEditedBy",
            "clicks",
            "_id",
            "createdBy",
            "isDeleted",
        ]);

        const res = await updateShortLink(id, cleanObj);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + shortLink?.clientName} />

            <ShortLinkForm
                shortLink={shortLink}
                disableLongURLEdit={true}
                onNameChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        name: e.target.value,
                    });
                }}
                onOriginalURLChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        originalURL: e.target.value,
                    });
                }}
                onShortCodeChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        shortCode: e.target.value,
                    });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/short-links")}
            />
        </DashboardLayout>
    );
}
