import { create } from "./create";
import { deleteBadge } from "./delete";
import { get } from "./get";
import { list } from "./list";
import { publish } from "./publish";
import { unPublish } from "./unpublish";
import { update } from "./update";

export const badgeHandler = {
    list,
    get,
    update,
    create,
    delete: deleteBadge,
    publish,
    unPublish,
};
