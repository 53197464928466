import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useCaseStudy } from "../../context/CaseStudyContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import CaseStudyForm from "./Form";

export default function EditCaseStudy() {
    const { getCaseStudyById, updateCaseStudy } = useCaseStudy();
    const { id } = useParams();
    const navigate = useNavigate();

    const [caseStudy, setCaseStudy] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const contentEditorRef = React.useRef();
    const slugRef = React.useRef();

    React.useEffect(() => {
        (async () => {
            const caseStudyResponse = await getCaseStudyById(id);

            setCaseStudy(caseStudyResponse.data[0]);
        })();
    }, [getCaseStudyById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/case-studies");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        const content = contentEditorRef.current.getContent();

        let updateObject = {
            ...caseStudy,
            content,
            slug: caseStudy.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "case-study",
            });

            updateObject = response?.key && {
                ...updateObject,
                image: response.key,
            };
        }

        delete updateObject._id;
        delete updateObject.createdBy;
        delete updateObject.editors;
        delete updateObject.createdAt;
        delete updateObject.updatedAt;
        delete updateObject.editors;
        delete updateObject.__v;
        delete updateObject.status;
        delete updateObject.lastEditedBy;

        const res = await updateCaseStudy(id, updateObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + caseStudy?.name} />

            <CaseStudyForm
                caseStudy={caseStudy}
                contentEditorRef={contentEditorRef}
                slugRef={slugRef}
                onNameChange={(e) => {
                    setCaseStudy({ ...caseStudy, name: e.target.value });
                }}
                onDescription={(e) => {
                    setCaseStudy({ ...caseStudy, description: e.target.value });
                }}
                onSlugChange={(e) => {
                    setCaseStudy({ ...caseStudy, slug: e.target.value });
                }}
                onPositionChange={(e) => {
                    setCaseStudy({
                        ...caseStudy,
                        position: parseInt(e.target.value),
                    });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanImage={() => {
                    setCaseStudy({ ...caseStudy, image: null });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/case-studies")}
            />
        </DashboardLayout>
    );
}
