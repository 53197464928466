import React from "react";
import { useNavigate } from "react-router-dom";
import InputWithLabel from "../../components/Input/WithLabel";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useRole } from "../../context/RoleContext";
import DashboardLayout from "../../layouts/Dashboard";
import RoleForm from "./Form";

export default function AddRole() {
    const navigate = useNavigate();
    const { allPermissions, getAllPermissions, createRole } = useRole();
    const [permissionList, setPermissionList] = React.useState([]);
    const [alias, setAlias] = React.useState("");

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        getAllPermissions();
    }, [getAllPermissions]);

    React.useEffect(() => {
        setPermissionList(allPermissions);
    }, [allPermissions]);

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        const data = {
            alias,
            permissionList: permissionList,
        };

        const result = await createRole(data);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/roles");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons
                title="Add Role"
                button1="Cancel"
                button2="Save"
                button1OnClick={() => navigate(-1)}
                button2OnClick={() => onSubmit()}
            />

            <InputWithLabel
                title="Alias"
                placeholder="Enter Role Alias"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                className="space-y-2 mt-3"
            />

            <RoleForm
                permissionList={permissionList}
                setPermissionList={setPermissionList}
            />
        </DashboardLayout>
    );
}
