import jsCookie from "js-cookie";
import React from "react";
import { blogHandler } from "../handler/blog";
const BlogContext = React.createContext();

export const useBlog = () => React.useContext(BlogContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function BlogProvider({ children }) {
    const [blogs, setBlogs] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [limit] = React.useState(10);
    const [pageList, setPageList] = React.useState([]);

    const getBlogs = React.useCallback(
        async (page) => {
            const result = await blogHandler.list(token, {
                page: page,
                limit: limit,
            });

            setBlogs(result.data);
            setTotalPages(result.totalPage);
            setCurrentPage(parseInt(result.page));
            setPageList(
                Array.from({ length: result.totalPage }, (v, k) => k + 1)
            );

            return result;
        },
        [limit]
    );

    const deleteBlog = async (id) => {
        const result = await blogHandler.delete(token, id);

        return result;
    };

    const getBlogById = React.useCallback(async (id) => {
        const result = await blogHandler.get(token, id);

        return result;
    }, []);

    const updateBlog = async (id, data) => {
        const result = await blogHandler.update(token, id, data);

        return result;
    };

    const createBlog = async (data) => {
        const result = await blogHandler.create(token, data);

        return result;
    };

    const publish = async (id) => {
        const result = await blogHandler.publish(token, id);

        return result;
    };

    const unpublish = async (id) => {
        const result = await blogHandler.unpublish(token, id);

        return result;
    };

    const getInfo = async (id) => {
        const result = await blogHandler.info(token, id);

        return result;
    };

    const contextValue = {
        blogs,
        getBlogs,
        deleteBlog,
        getBlogById,
        updateBlog,
        createBlog,
        publish,
        unpublish,
        getInfo,
        currentPage,
        totalPages,
        pageList,
    };

    return (
        <BlogContext.Provider value={contextValue}>
            {children}
        </BlogContext.Provider>
    );
}
