import jsCookie from "js-cookie";
import React from "react";
import loginHandler from "../handler/login";
import { userHandler } from "../handler/user";
const AuthContext = React.createContext();

export const useAuth = () => React.useContext(AuthContext);

export function AuthProvider({ children }) {
    const [user, setUser] = React.useState(null);

    const login = async (data) => {
        const result = await loginHandler(data.username, data.password);

        if (result.data?.token) {
            jsCookie.set(process.env.REACT_APP_JWT_TOKEN, result.data.token);

            setUser(result.data);
        }

        return result;
    };

    const getProfile = React.useCallback(async () => {
        const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

        if (!token) {
            return;
        }

        const result = await userHandler.getProfile(token);

        setUser(result);

        return result;
    }, []);

    const logout = () => {
        jsCookie.remove(process.env.REACT_APP_JWT_TOKEN);
        setUser(null);
    };

    const contextValue = {
        user,
        setUser,
        login,
        logout,
        getProfile,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}
