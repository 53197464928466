import jsCookie from "js-cookie";
import React from "react";
import { caseStudyHandler } from "../handler/caseStudy";

const CaseStudyContext = React.createContext();

export const useCaseStudy = () => React.useContext(CaseStudyContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function CaseStudyProvider({ children }) {
    const [caseStudies, setCaseStudies] = React.useState(null);

    const getAllCaseStudy = React.useCallback(async () => {
        const result = await caseStudyHandler.list(token);

        setCaseStudies(result);

        return result;
    }, []);

    const getCaseStudyById = async (id) => {
        const result = await caseStudyHandler.get(token, id);

        return result;
    };

    const updateCaseStudy = async (id, data) => {
        const result = await caseStudyHandler.update(token, id, data);

        return result;
    };

    const createCaseStudy = async (data) => {
        const result = await caseStudyHandler.create(token, data);

        return result;
    };

    const deleteCaseStudy = async (id) => {
        const result = await caseStudyHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await caseStudyHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await caseStudyHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        caseStudies,
        getAllCaseStudy,
        getCaseStudyById,
        updateCaseStudy,
        createCaseStudy,
        deleteCaseStudy,
        publish,
        unPublish,
    };

    return (
        <CaseStudyContext.Provider value={contextValue}>
            {children}
        </CaseStudyContext.Provider>
    );
}
