import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { imageHandler } from "../../handler/image";

export default function TinyMCEditor({
    editorRef,
    className,
    initialValue = "",
    placeholder,
    plugins = [
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "code",
        "help",
        "wordcount",
        "emoticons",
        "codesample",
    ],
    toolbar = `undo redo | 
    image link anchor | 
    bold italic underline forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | 
    bullist numlist outdent indent | 
    codesample code preview fullscreen`,
    menubar = "insert edit view format table tools",
    height = 500,
}) {
    return (
        <div className={`${className ? className : null}`}>
            <Editor
                apiKey={process.env.REACT_APP_TINY_API_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={initialValue}
                init={{
                    height: height,
                    plugins: plugins,
                    placeholder: placeholder,
                    toolbar: toolbar,
                    menubar: menubar,
                    image_title: true,
                    /* enable automatic uploads of images represented by blob or data URIs*/
                    automatic_uploads: true,
                    file_picker_types: "image",
                    file_picker_callback: function (cb) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = async function () {
                            var file = this.files[0];

                            const formData = new FormData();
                            formData.append("folder", "blog");
                            formData.append("image", file);

                            console.log(formData);

                            const { key } = await imageHandler.upload(formData);

                            // setting the image url using key and server name from .env
                            const fileUrl = `${process.env.REACT_APP_FILE_SERVER}/${key}`;

                            cb(fileUrl, {
                                title: file.name,
                            });
                        };

                        input.click();
                    },
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    paste_block_drop: false,
                    paste_data_images: true,
                    paste_as_text: true,
                    branding: false,
                    image_dimensions: false,
                    // set max image width
                    setup: function (editor) {
                        editor.on("init", function (args) {
                            editor = args.target;

                            editor.on("NodeChange", function (e) {
                                if (
                                    e &&
                                    e.element.nodeName.toLowerCase() === "img"
                                ) {
                                    let width = e.element.width;
                                    let height = e.element.height;
                                    if (width > 1000) {
                                        height = height / (width / 1000);
                                        width = 1000;

                                        window.tinyMCE.DOM.setAttribs(
                                            e.element,
                                            {
                                                width: width,
                                                height: height,
                                            }
                                        );
                                    }
                                }
                            });
                        });
                    },
                }}
            />
        </div>
    );
}
