const { PencilIcon } = require("@heroicons/react/24/outline");
const { default: AddInjectScript } = require("../../pages/InjectScripts/Add");
const {
    default: EditInjectScripts,
} = require("../../pages/InjectScripts/Edit");

const { default: AddBadge } = require("../../pages/Badge/Add");
const { default: EditBadge } = require("../../pages/Badge/Edit");
const {
    default: AddOfficeLocation,
} = require("../../pages/OfficeLocation/Add");
const {
    default: EditOfficeLocation,
} = require("../../pages/OfficeLocation/Edit");

module.exports = (Permission) => [
    {
        name: "Badge Add",
        href: "/badges/add",
        icon: PencilIcon,
        current: false,
        parent: "Badge",
        component: AddBadge,
        hidden: true,
        permissions: [Permission.CREATE_BADGE],
    },
    {
        name: "Badge Edit",
        href: "/badges/edit/:id",
        icon: PencilIcon,
        current: false,
        parent: "Badge",
        component: EditBadge,
        hidden: true,
        permissions: [Permission.UPDATE_BADGE],
    },
    {
        name: "Office Locations Add",
        href: "/settings/offices/add",
        icon: PencilIcon,
        current: false,
        parent: "Office Locations",
        component: AddOfficeLocation,
        hidden: true,
        permissions: [Permission.CREATE_OFFICE],
    },
    {
        name: "Office Locations Edit",
        href: "/settings/offices/edit/:id",
        icon: PencilIcon,
        current: false,
        parent: "Office Locations",
        component: EditOfficeLocation,
        hidden: true,
        permissions: [Permission.UPDATE_OFFICE],
    },
    {
        name: "Add",
        href: "/settings/inject-scripts/add",
        icon: PencilIcon,
        current: false,
        parent: "Inject Scripts (Head)",
        component: AddInjectScript,
        hidden: true,
        permissions: [Permission.CREATE_INJECT_SCRIPT],
    },
    {
        name: "Edit",
        href: "/settings/inject-scripts/edit/:id",
        icon: PencilIcon,
        current: false,
        parent: "Inject Scripts (Head)",
        component: EditInjectScripts,
        hidden: true,
        permissions: [Permission.UPDATE_INJECT_SCRIPT],
    },
];
