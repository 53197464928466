import React from "react";
import routes from "../configs/routes";
import { hasPermission } from "../utils/permission";
import { useAuth } from "./AuthContext";
const MenuContext = React.createContext();

export const useMenu = () => React.useContext(MenuContext);

export function MenuProvider({ children }) {
    const { user, getProfile } = useAuth();
    const [navigation, setNavigation] = React.useState([]);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);

    React.useEffect(() => {
        getProfile();
    }, [getProfile]);

    React.useEffect(() => {
        setNavigation(
            routes.filter((route) => {
                return (
                    user &&
                    hasPermission(user?.role.permissions, route.permissions)
                );
            })
        );
    }, [user]);

    const contextValue = {
        user,
        navigation,
        setNavigation,
        sidebarOpen,
        setSidebarOpen,
    };

    return (
        <MenuContext.Provider value={contextValue}>
            {children}
        </MenuContext.Provider>
    );
}
