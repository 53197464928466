import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import ProfileForm from "./Form";

export default function EditProfile() {
    const { updateProfile } = useUser();
    const { user, setUser, getProfile } = useAuth();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/profile");
            }, 2000);
        }
    });

    const submitForm = async (e) => {
        e.preventDefault();

        let updateObject = { ...user };

        delete updateObject._id;
        delete updateObject.updatedAt;
        delete updateObject.createdAt;
        delete updateObject.__v;
        delete updateObject.editors;
        delete updateObject.lastEditedBy;
        delete updateObject.createdBy;
        delete updateObject.role;
        delete updateObject.username;
        delete updateObject.isDeleted;

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "user",
            });

            updateObject = response?.key && {
                ...updateObject,
                profileImage: response.key,
            };
        }

        const result = await updateProfile(updateObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        getProfile();

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {user && (
                <ProfileForm
                    user={user}
                    submitButtonText="Update"
                    sectionHeader="Edit Profile"
                    onClick={submitForm}
                    onNameChange={(e) => {
                        setUser({ ...user, name: e.target.value });
                    }}
                    onDesignationChange={(e) => {
                        setUser({ ...user, designation: e.target.value });
                    }}
                    onProfileImageChange={(image, name) => {
                        setImage({ file: image, name: name });
                    }}
                    cleanProfileImage={() => {
                        setUser({ ...user, profileImage: null });
                    }}
                    onDiscard={() => {
                        setTimeout(() => {
                            navigate(-1);
                        }, 500);
                    }}
                />
            )}
        </DashboardLayout>
    );
}
