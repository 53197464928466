import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import { IMAGES } from "../../lib/slug";
import { generateImageURL } from "../../utils";

export default function BadgeForm({
    badge,
    onNameChange,
    onURLChange,
    onPositionChange,
    onImageChange,
    onAltChange,
    onCleanImage,
    onClick,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="name"
                                label="Name"
                                htmlFor="name"
                                showLabel={true}
                                ariaDescribeBy="name-helper-text"
                                hint="Name of the badge"
                            >
                                <TextareaInput
                                    htmlFor="name"
                                    value={badge?.name || ""}
                                    onChange={onNameChange}
                                    placeholder="Enter name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Image"
                                htmlFor="image"
                                showLabel={true}
                                ariaDescribeBy="image-helper-text"
                                hint=""
                            >
                                <ImageInput
                                    getData={onImageChange}
                                    image={
                                        badge?.image &&
                                        generateImageURL(IMAGES, badge.image)
                                    }
                                    cleanImage={onCleanImage}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="alt"
                                label="Alt"
                                htmlFor="alt"
                                showLabel={true}
                                ariaDescribeBy="alt-helper-text"
                                hint="Alt of the badge"
                            >
                                <TextareaInput
                                    htmlFor="alt"
                                    value={badge?.alt || ""}
                                    onChange={onAltChange}
                                    placeholder="Enter alt"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="url"
                                label="URL"
                                htmlFor="url"
                                showLabel={true}
                                ariaDescribeBy="url-helper-text"
                                hint="URL of the badge"
                            >
                                <TextareaInput
                                    htmlFor="url"
                                    value={badge?.url || ""}
                                    onChange={onURLChange}
                                    placeholder="Enter url"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="position"
                                label="Position"
                                htmlFor="position"
                                showLabel={true}
                                ariaDescribeBy="position-helper-text"
                                hint="Position of the badge to be displayed on the website"
                            >
                                <TextareaInput
                                    htmlFor="position"
                                    value={badge?.position || ""}
                                    onChange={onPositionChange}
                                    placeholder="Enter position"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
