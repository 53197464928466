import React from "react";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import FullWidthWithHeaderCard from "../../components/Card/FullWidthWithHeader";
import InlineCheckbox from "../../components/Checkbox/Inline";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import { IMAGES } from "../../lib/slug";
import { generateImageURL } from "../../utils";

export default function Uploader({
    onCancel,
    image,
    uploadButtonText = "Upload",
    cardTitle = "New media",
}) {
    const onTitleChange = (e) => {};
    const onFeaturedImageChange = (e) => {};
    const cleanFeaturedImage = (e) => {};

    return (
        <>
            <FullWidthWithHeaderCard
                headerItems={
                    <>
                        <FlexOneLineJustifyBetween>
                            <p>{cardTitle}</p>

                            <SecondaryButton name="Cancel" onClick={onCancel} />
                        </FlexOneLineJustifyBetween>
                    </>
                }
                body={
                    <div className="space-y-5">
                        <LabelAndHelperWrapper
                            key="title"
                            label="Title"
                            htmlFor="title"
                            showLabel={true}
                            ariaDescribeBy="title-helper-text"
                            hint="Title of the image"
                        >
                            <TextareaInput
                                htmlFor="title"
                                value={image?.title || ""}
                                onChange={onTitleChange}
                                placeholder="Enter title"
                            />
                        </LabelAndHelperWrapper>

                        <LabelAndHelperWrapper
                            label="Image"
                            htmlFor="image"
                            showLabel={true}
                            ariaDescribeBy="image-helper-text"
                            hint="This will be used as the image for the blog"
                        >
                            <ImageInput
                                getData={onFeaturedImageChange}
                                image={
                                    image?.url &&
                                    generateImageURL(IMAGES, image.url)
                                }
                                cleanImage={cleanFeaturedImage}
                            />
                        </LabelAndHelperWrapper>

                        <LabelAndHelperWrapper
                            key="alt"
                            label="Alt"
                            htmlFor="alt"
                            showLabel={true}
                            ariaDescribeBy="alt-helper-text"
                            hint="Alt of the image"
                        >
                            <TextareaInput
                                htmlFor="alt"
                                value={image?.alt || ""}
                                onChange={onTitleChange}
                                placeholder="Enter alt text"
                            />
                        </LabelAndHelperWrapper>

                        <LabelAndHelperWrapper
                            key="caption"
                            label="Caption"
                            htmlFor="caption"
                            showLabel={true}
                            ariaDescribeBy="caption-helper-text"
                            hint="Caption of the image"
                        >
                            <TextareaInput
                                htmlFor="caption"
                                rows={2}
                                value={image?.caption || ""}
                                onChange={onTitleChange}
                                placeholder="Enter caption"
                            />
                        </LabelAndHelperWrapper>

                        <LabelAndHelperWrapper
                            key="description"
                            label="Description"
                            htmlFor="description"
                            showLabel={true}
                            ariaDescribeBy="description-helper-text"
                            hint="Description of the image"
                        >
                            <TextareaInput
                                htmlFor="description"
                                rows={3}
                                value={image?.description || ""}
                                onChange={onTitleChange}
                                placeholder="Enter description"
                            />
                        </LabelAndHelperWrapper>

                        <InlineCheckbox
                            isChecked={image?.isExcludedFromSitemap}
                            onChange={() => {}}
                            legendText={"Exclude from sitemap"}
                            option={"Exclude from sitemap?"}
                        />

                        <PrimaryButton
                            name={uploadButtonText}
                            onClick={() => {}}
                        />
                    </div>
                }
            />
        </>
    );
}
