import React from "react";
import { useNavigate } from "react-router-dom";
import ActionConfirmer from "../components/ActionConfirmer";
import PrimaryButton from "../components/Button/Primary";
import SecondaryButton from "../components/Button/Secondary";
import CardWithHeaderFooter from "../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../components/Design/OneLiner/FlexJustifyEnd";
import LabelAndHelperWrapper from "../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../components/Input/Textarea";
import Toast from "../components/Notification/Toast";
import { useUser } from "../context/UserContext";
import DashboardLayout from "../layouts/Dashboard";

export default function Settings() {
    const navigate = useNavigate();
    const { changePassword } = useUser();
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await changePassword({
            oldPassword: currentPassword,
            newPassword: newPassword,
        });

        setToastMessage(result.message);
        setShowToast(true);

        if (result.status !== 200) {
            setIsError(true);
        }
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);

                !isError && navigate(-1);
            }, 1000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">Change Password</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="current-password"
                                label="Current Password"
                                htmlFor="current-password"
                                showLabel={true}
                                ariaDescribeBy="current-password-helper-text"
                                hint={"Your current password"}
                            >
                                <TextareaInput
                                    htmlFor="current-password"
                                    value={currentPassword}
                                    onChange={(e) =>
                                        setCurrentPassword(e.target.value)
                                    }
                                    placeholder="Enter password"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="new-password"
                                label="New Password"
                                htmlFor="new-password"
                                showLabel={true}
                                ariaDescribeBy="new-password-helper-text"
                                hint={"Your new password"}
                            >
                                <TextareaInput
                                    htmlFor="new-password"
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    placeholder="Enter password"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={"Change Password"}
                                        onClick={(e) => handleSubmit(e)}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={() => navigate("/")}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </DashboardLayout>
    );
}
