import { Permission } from "../../utils/permissionStrings";

const dashboard = require("./dashboard")(Permission);
const profile = require("./profile")(Permission);
const contentHub = require("./contentHub")(Permission);
const jobs = require("./jobs")(Permission);
const urlShortner = require("./urlShortner")(Permission);
const testimonials = require("./testimonial")(Permission);
const unlisted = require("./unlisted")(Permission);
const systemAdministration = require("./systemAdministration")(Permission);
const settings = require("./settings")(Permission);

const blogs = require("./blogs")(Permission);
const roles = require("./roles")(Permission);
const users = require("./users")(Permission);
const caseStudies = require("./caseStudies")(Permission);
const products = require("./products")(Permission);

const menu = [
    ...dashboard,
    ...profile,

    ...contentHub,
    ...jobs,

    ...urlShortner,
    ...testimonials,
    ...systemAdministration,
    ...settings,
    ...unlisted,
    ...blogs,
    ...roles,
    ...users,
    ...caseStudies,
    ...products,
];

export default menu;
