import React from "react";

export default function GenericTwoFlex({ Child1, Child2 }) {
    return (
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">{Child1}</div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{Child2}</div>
        </div>
    );
}
