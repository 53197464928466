const {
    PencilIcon,
    UserPlusIcon,
    UsersIcon,
} = require("@heroicons/react/24/outline");
const { default: EditUser } = require("../../pages/User/EditUser");
const { default: AddUser } = require("../../pages/User/AddUser");
const { default: Users } = require("../../pages/User");

module.exports = (Permission) => [
    {
        name: "Users",
        // href: "/users",
        icon: UsersIcon,
        current: false,
        component: Users,
        hidden: true,
        permissions: [Permission.READ_USER],
        children: [
            {
                name: "Add",
                href: "/users/add",
                icon: UserPlusIcon,
                current: true,
                parent: "Users",
                component: AddUser,
                permissions: [Permission.CREATE_USER],
            },
            {
                name: "Edit",
                href: "/users/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Users",
                component: EditUser,
                hidden: true,
                permissions: [Permission.UPDATE_USER],
            },
        ],
    },
];
