import { classNames } from "../../../utils";

export default function FlexOneLineJustifyBetween({ children, className }) {
    return (
        <div
            className={classNames(
                "flex justify-between items-center",
                className
            )}
        >
            {children}
        </div>
    );
}
