import jsCookie from "js-cookie";
import React from "react";
import { roleHandler } from "../handler/role";

const RoleContext = React.createContext();

export const useRole = () => React.useContext(RoleContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function RoleProvider({ children }) {
    const [roles, setRoles] = React.useState(null);
    const [allPermissions, setAllPermissions] = React.useState(null);

    const getAllRoles = React.useCallback(async () => {
        const result = await roleHandler.list(token);

        setRoles(result);

        return result;
    }, []);

    const getRoleById = React.useCallback(async (id) => {
        const result = await roleHandler.get(token, id);

        return result;
    }, []);

    const updateRole = async (id, data) => {
        const result = await roleHandler.update(token, id, data);

        return result;
    };

    const getAllPermissions = React.useCallback(async () => {
        const result = await roleHandler.listPermissions(token);

        setAllPermissions(result);

        return result;
    }, []);

    const createRole = async (data) => {
        const result = await roleHandler.create(token, data);

        return result;
    };

    const deleteRole = async (id) => {
        const result = await roleHandler.delete(token, id);

        return result;
    };

    const contextValue = {
        roles,
        getAllRoles,
        getRoleById,
        updateRole,
        allPermissions,
        getAllPermissions,
        createRole,
        deleteRole,
    };

    return (
        <RoleContext.Provider value={contextValue}>
            {children}
        </RoleContext.Provider>
    );
}
