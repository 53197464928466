import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useRole } from "../../context/RoleContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import RoleList from "./List";

export default function Roles() {
    const { roles, getAllRoles, deleteRole } = useRole();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllRoles();
    }, [getAllRoles]);

    const onDeleteRole = async (id) => {
        const res = await deleteRole(id);

        getAllRoles();

        setToastMessage(res.message);
        setShowToast(true);
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {roles && (
                <RoleList
                    requiredPermissions={{
                        create: Permission.CREATE_ROLE,
                        update: Permission.UPDATE_ROLE,
                        delete: Permission.DELETE_ROLE,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Roles"}
                    description={"List of all roles"}
                    addButton={"Add Role"}
                    addButtonOnClick={() => navigate("/roles/add")}
                    deleteButtonOnClick={onDeleteRole}
                    tableHeads={["Alias"]}
                    tableData={roles.data}
                />
            )}
        </DashboardLayout>
    );
}
