import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import RedirectToHome from "./components/RedirectToHome";
import NotFound from "./components/404";
import Sidebar from "./components/Sidebar";
import routes from "./configs/routes";
import { useAuth } from "./context/AuthContext";
import { useMenu } from "./context/MenuContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { hasPermission } from "./utils/permission";

function App() {
    const { sidebarOpen, setSidebarOpen } = useMenu();
    const { user } = useAuth();

    const [sideBarDisplay, setSideBarDisplay] = React.useState(false);

    React.useEffect(() => {
        if (window.location.pathname !== "/login") {
            setSideBarDisplay(true);
        } else {
            setSideBarDisplay(false);
        }
    }, []);

    return (
        <>
            <BrowserRouter>
                {sideBarDisplay && (
                    <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                )}
                <Routes>
                    <Route path="/login" element={<Login />} />

                    <Route path="/" element={<Home />} />

                    {routes.map((route, index) => (
                        <React.Fragment key={index}>
                            {hasPermission(
                                user?.role.permissions,
                                route.permissions
                            ) && (
                                <Route
                                    path={route.href}
                                    element={
                                        route.component ? (
                                            <route.component />
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )}

                            {route.children &&
                                route.children.map((child, index) => (
                                    <React.Fragment key={index}>
                                        {hasPermission(
                                            user?.role.permissions,
                                            child.permissions
                                        ) && (
                                            <Route
                                                path={child.href}
                                                element={<child.component />}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                        </React.Fragment>
                    ))}

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
