const { HomeIcon } = require("@heroicons/react/24/outline");
const { default: Home } = require("../../pages/Home");

module.exports = (Permission) => {
    return [
        {
            name: "Dashboard",
            href: "/",
            icon: HomeIcon,
            current: false,
            component: Home,
            hidden: false,
            permissions: [Permission.ALL],
        },
    ];
};
