import { Link } from "react-router-dom";
import { classNames } from "../../utils";

export const RenderLink = ({ item, isChild }) => (
    <Link
        to={item.href}
        className={classNames(
            "text-gray-100 group flex items-center px-2 py-2 text-sm font-medium",
            isChild ? "pl-8" : ""
        )}
    >
        <item.icon
            className={classNames("mr-3 flex-shrink-0 h-6 w-6")}
            aria-hidden="true"
        />
        {item.name}
    </Link>
);

export const RenderParentItem = ({ item, isChild }) => (
    <div
        className={classNames(
            "group flex items-center px-2 py-2 text-sm font-medium",
            isChild ? "pl-5" : ""
        )}
    >
        <item.icon
            className={classNames("mr-3 flex-shrink-0 h-6 w-6")}
            aria-hidden="true"
        />
        {item.name}
    </div>
);
