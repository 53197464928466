import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useTestimonial } from "../../context/TestimonialContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import TestimonialForm from "./Form";

export default function EditTestimonial() {
    const { getTestimonialById, updateTestimonial } = useTestimonial();
    const { id } = useParams();
    const navigate = useNavigate();

    const [testimonial, setTestimonial] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [clientImage, setClientImage] = React.useState(null);
    const [clientCompanyLogo, setClientCompanyLogo] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            const testimonialResponse = await getTestimonialById(id);

            setTestimonial(testimonialResponse.data[0]);
        })();
    }, [getTestimonialById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/testimonials");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        let updateObject = {
            ...testimonial,
        };

        if (clientImage) {
            const response = await imageHandler.uploadV2({
                image: clientImage,
                folder: "testimonial",
            });

            updateObject = response?.key && {
                ...updateObject,
                clientImage: response.key,
            };
        }

        if (clientCompanyLogo) {
            const response = await imageHandler.uploadV2({
                image: clientCompanyLogo,
                folder: "testimonial",
            });

            updateObject = response?.key && {
                ...updateObject,
                clientCompanyLogo: response.key,
            };
        }

        delete updateObject._id;
        delete updateObject.createdBy;
        delete updateObject.editors;
        delete updateObject.createdAt;
        delete updateObject.updatedAt;
        delete updateObject.editors;
        delete updateObject.__v;
        delete updateObject.status;
        delete updateObject.lastEditedBy;

        const res = await updateTestimonial(id, updateObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + testimonial?.clientName} />

            <TestimonialForm
                testimonial={testimonial}
                onClientNameChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientName: e.target.value,
                    });
                }}
                onClientTitleChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientTitle: e.target.value,
                    });
                }}
                onClientCommentChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        clientComment: e.target.value,
                    });
                }}
                onPositionChange={(e) => {
                    setTestimonial({
                        ...testimonial,
                        position: parseInt(e.target.value),
                    });
                }}
                onClientImageChange={(image, name) => {
                    setClientImage({ file: image, name: name });
                }}
                cleanClientImage={() => {
                    setTestimonial({ ...testimonial, clientImage: null });
                }}
                onClientCompanyLogoChange={(image, name) => {
                    setClientCompanyLogo({ file: image, name: name });
                }}
                cleanClientCompanyLogo={() => {
                    setTestimonial({ ...testimonial, clientCompanyLogo: null });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/testimonials")}
            />
        </DashboardLayout>
    );
}
