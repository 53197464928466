import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BlockCenteredModal from "../../components/Modal/BlockCenter";
import NewItem from "../../components/NewItem";
import Toast from "../../components/Notification/Toast";
import PagingWithNumber from "../../components/Pagination/PagingWithNumber";
import { useAuth } from "../../context/AuthContext";
import { useBlog } from "../../context/BlogContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import List from "./List";

export default function Blogs() {
    const navigate = useNavigate();
    const {
        getBlogs,
        blogs,
        deleteBlog,
        publish,
        unpublish,
        currentPage,
        totalPages,
        pageList,
        getInfo,
    } = useBlog();

    const [searchParams] = useSearchParams();
    const { user } = useAuth();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        getBlogs(searchParams.get("p") || 1);
    }, [searchParams, getBlogs]);

    const onDelete = async (id) => {
        const res = await deleteBlog(id);

        setToastMessage(res.message);
        setShowToast(true);

        getBlogs();
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getBlogs();
    };

    const onUnpublish = async (id) => {
        const result = await unpublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getBlogs();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {!blogs ? (
                <BlockCenteredModal>
                    <NewItem
                        name="post"
                        onClick={() => navigate("/blogs/add")}
                    />
                </BlockCenteredModal>
            ) : (
                <>
                    <List
                        requiredPermissions={{
                            create: Permission.CREATE_BLOG,
                            update: Permission.UPDATE_BLOG,
                            delete: Permission.DELETE_BLOG,
                            publish: Permission.PUBLISH_BLOG,
                            unpublish: Permission.UNPUBLISH_BLOG,
                            info: Permission.SEE_BLOG_INFO,
                        }}
                        userPermissions={user?.role?.permissions}
                        title={"Blogs"}
                        description={"List of all blogs"}
                        addButton={"Add blog"}
                        addButtonOnClick={() => navigate("/blogs/add")}
                        deleteButtonOnClick={onDelete}
                        tableHeads={["Title", "Category", "Status"]}
                        tableData={blogs}
                        onPublish={onPublish}
                        onUnpublish={onUnpublish}
                        getInfo={getInfo}
                    />

                    {totalPages > 1 && (
                        <PagingWithNumber
                            linkPrefix={"/blogs"}
                            pageList={pageList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPrevious={() =>
                                navigate("/blogs?p=" + (currentPage - 1))
                            }
                            onNext={() =>
                                navigate("/blogs?p=" + (currentPage + 1))
                            }
                        />
                    )}
                </>
            )}
        </DashboardLayout>
    );
}
