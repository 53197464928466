import {
    ArrowDownOnSquareIcon,
    ArrowDownTrayIcon,
    ArrowPathIcon,
    ArrowRightOnRectangleIcon,
    ArrowUpOnSquareIcon,
    ArrowUpTrayIcon,
    ArrowUturnLeftIcon,
    BookOpenIcon,
    ClipboardDocumentListIcon,
    DocumentCheckIcon,
    DocumentIcon,
    DocumentTextIcon,
    EnvelopeIcon,
    EnvelopeOpenIcon,
    FingerPrintIcon,
    GiftTopIcon,
    InboxArrowDownIcon,
    ListBulletIcon,
    MagnifyingGlassIcon,
    NewspaperIcon,
    PaperAirplaneIcon,
    PhoneIcon,
    PlusCircleIcon,
    PrinterIcon,
    ShieldCheckIcon,
    ShieldExclamationIcon,
    SignalSlashIcon,
    TrashIcon,
    UserCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";

export const actions = {
    LOGIN: { text: "Logged In", icon: <FingerPrintIcon className="w-5" /> },
    LOGOUT: {
        text: "Logged Out",
        icon: <ArrowRightOnRectangleIcon className="w-5" />,
    },
    CREATE: { text: "Created", icon: <PlusCircleIcon className="w-5" /> },
    UPDATE: { text: "Updated", icon: <ArrowPathIcon className="w-5" /> },
    DELETE: { text: "Deleted", icon: <TrashIcon className="w-5" /> },
    READ: { text: "Read", icon: <DocumentTextIcon className="w-5" /> },
    READ_ALL: {
        text: "Read All",
        icon: <ClipboardDocumentListIcon className="w-5" />,
    },
    READ_SINGLE: {
        text: "Read Single",
        icon: <DocumentIcon className="w-5" />,
    },
    SEARCH: { text: "Searched", icon: <MagnifyingGlassIcon className="w-5" /> },
    EXPORT: { text: "Exported", icon: <ArrowUpTrayIcon className="w-5" /> },
    IMPORT: { text: "Imported", icon: <ArrowDownTrayIcon className="w-5" /> },
    PRINT: { text: "Printed", icon: <PrinterIcon className="w-5" /> },
    DOWNLOAD: {
        text: "Downloaded",
        icon: <ArrowDownOnSquareIcon className="w-5" />,
    },
    UPLOAD: { text: "Uploaded", icon: <ArrowUpOnSquareIcon className="w-5" /> },
    VIEW: { text: "Viewed", icon: <DocumentCheckIcon className="w-5" /> },
    RECOMMENDED: {
        text: "Recommended",
        icon: <HandThumbUpIcon className="w-5" />,
    },
    NOT_RECOMMENDED: {
        text: "Not-recommended",
        icon: <HandThumbDownIcon className="w-5" />,
    },
    APPROVE: { text: "Approved", icon: <ShieldCheckIcon className="w-5" /> },
    DISAPPROVE: {
        text: "disapproved",
        icon: <ShieldExclamationIcon className="w-5" />,
    },
    REJECT: {
        text: "Rejected",
        icon: <ShieldExclamationIcon className="w-5" />,
    },
    REVERT: { text: "Reverted", icon: <ArrowUturnLeftIcon className="w-5" /> },
    SEND: { text: "Sent", icon: <PaperAirplaneIcon className="w-5" /> },
    RECEIVE: { text: "Recieved", icon: <InboxArrowDownIcon className="w-5" /> },
    CANCEL: { text: "Cancelled", icon: <XMarkIcon className="w-5" /> },
    CLOSE: { text: "Closed", icon: <GiftTopIcon className="w-5" /> },
    OPEN: { text: "Opened", icon: <BookOpenIcon className="w-5" /> },
    PUBLISH: { text: "Published", icon: <NewspaperIcon className="w-5" /> },
    UNPUBLISH: {
        text: "Unpublished",
        icon: <SignalSlashIcon className="w-5" />,
    },
    READ_INFO: { text: "Read Info", icon: <ListBulletIcon className="w-5" /> },
    CHANGE_PASSWORD: {
        text: "Changed Password",
        icon: <FingerPrintIcon className="w-5" />,
    },
    RESET_PASSWORD: {
        text: "Reset Password",
        icon: <FingerPrintIcon className="w-5" />,
    },
    FORGOT_PASSWORD: {
        text: "Forgot Password",
        icon: <FingerPrintIcon className="w-5" />,
    },
    VERIFY_EMAIL: {
        text: "Verified Email",
        icon: <EnvelopeOpenIcon className="w-5" />,
    },
    CHANGE_EMAIL: {
        text: "Changed Email",
        icon: <EnvelopeIcon className="w-5" />,
    },
    RESET_EMAIL: {
        text: "Reset Email",
        icon: <EnvelopeIcon className="w-5" />,
    },
    FORGOT_EMAIL: {
        text: "Forgot Email",
        icon: <EnvelopeIcon className="w-5" />,
    },
    VERIFY_PHONE: {
        text: "Verified Phone",
        icon: <PhoneIcon className="w-5" />,
    },
    CHANGE_PHONE: {
        text: "Changed Phone",
        icon: <PhoneIcon className="w-5" />,
    },
    RESET_PHONE: { text: "Reset Phone", icon: <PhoneIcon className="w-5" /> },
    READ_PROFILE: {
        text: "Read Profile",
        icon: <UserCircleIcon className="w-5" />,
    },
    UPDATE_PROFILE: {
        text: "Updated Profile",
        icon: <UserCircleIcon className="w-5" />,
    },
};
