import React from "react";
import Logout from "./Logout";
import Nav from "./Nav";

export default function Mobile({ navigation }) {
    return (
        <>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <Nav navigation={navigation} />
            </div>

            <Logout />
        </>
    );
}
