import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexBasisEven from "../../components/Design/OneLiner/FlexBasisEven";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import SimpleDropdown from "../../components/Dropdown/Simple";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import InputWithLabel from "../../components/Input/WithLabel";
import TinyMCEditor from "../../components/TinyMCEditor";
import { jobNatures, jobTypes } from "../../lib";
import { IMAGES } from "../../lib/slug";
import { generateImageURL, getSlugFromSentence } from "../../utils";

export default function JobForm({
    job,
    onClick,
    onTitleChange,
    onSlugChange,
    onTypeChange,
    onLocationChange,
    onDeadlineChange,
    onFeaturedImageChange,
    onNoOfVacanciesChange,
    onExperienceChange,
    cleanFeaturedImage,
    slugRef,
    submitButtonText,
    sectionHeader,
    jobContentEditorRef,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="Title"
                                label="Title"
                                htmlFor="title"
                                showLabel={true}
                                ariaDescribeBy="title-helper-text"
                                hint="Title of the job"
                            >
                                <TextareaInput
                                    htmlFor="title"
                                    value={job?.title || ""}
                                    onChange={onTitleChange}
                                    placeholder="Enter title"
                                />
                            </LabelAndHelperWrapper>
                            <LabelAndHelperWrapper
                                label="Featured Image"
                                htmlFor="featured-image"
                                showLabel={true}
                                ariaDescribeBy="featured-image-helper-text"
                                hint="This will be used as the featured image for the job"
                            >
                                <ImageInput
                                    getData={onFeaturedImageChange}
                                    image={
                                        job?.featuredImage &&
                                        generateImageURL(
                                            IMAGES,
                                            job.featuredImage
                                        )
                                    }
                                    cleanImage={cleanFeaturedImage}
                                />
                            </LabelAndHelperWrapper>
                            <LabelAndHelperWrapper
                                label="Content"
                                htmlFor="job-content"
                                showLabel={true}
                            >
                                <TinyMCEditor
                                    editorRef={jobContentEditorRef}
                                    placeholder="Enter Job description here"
                                    initialValue={job?.description || ""}
                                />
                            </LabelAndHelperWrapper>
                            <FlexBasisEven
                                kids={[
                                    <SimpleDropdown
                                        label="Type"
                                        defaultValue="Select type"
                                        options={jobTypes}
                                        selected={job?.type}
                                        onSelect={onTypeChange}
                                    />,
                                    <SimpleDropdown
                                        label="Nature"
                                        defaultValue="Select nature"
                                        options={jobNatures}
                                        selected={job?.nature}
                                        onSelect={onLocationChange}
                                    />,

                                    <InputWithLabel
                                        title="Deadline"
                                        type="date"
                                        value={job?.deadline || ""}
                                        onChange={onDeadlineChange}
                                    />,
                                ]}
                            />
                            <FlexBasisEven
                                kids={[
                                    <InputWithLabel
                                        title="Experience"
                                        type="text"
                                        value={job?.experience || ""}
                                        onChange={onExperienceChange}
                                    />,

                                    <InputWithLabel
                                        title="No. of Vacancies"
                                        type="number"
                                        value={job?.noOfVacancies || ""}
                                        onChange={onNoOfVacanciesChange}
                                    />,

                                    // <SimpleDropdown
                                    //     label="Status"
                                    //     defaultValue="Select status"
                                    //     options={jobStatuses}
                                    //     selected={
                                    //         job?.active?.toString() || "true"
                                    //     }
                                    //     onSelect={onActiveChange}
                                    // />,
                                ]}
                            />
                            <LabelAndHelperWrapper
                                key="Slug"
                                label="Slug"
                                htmlFor="slug"
                                showLabel={true}
                                ariaDescribeBy="slug-helper-text"
                                hint="This will be used in the URL"
                            >
                                <TextareaInput
                                    htmlFor="slug"
                                    onChange={onSlugChange}
                                    reference={slugRef}
                                    value={
                                        job?.slug ||
                                        (job &&
                                            getSlugFromSentence({
                                                sentence:
                                                    job?.title +
                                                    "-" +
                                                    job?.nature +
                                                    "-" +
                                                    job?.type,
                                            })) ||
                                        ""
                                    }
                                    placeholder="Enter slug"
                                />
                            </LabelAndHelperWrapper>
                            {/* // TODO: Add Job excerpt */}
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Create"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
