import React from "react";
import Logout from "./Logout";
import Nav from "./Nav";

export default function Desktop({ navigation }) {
    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <Nav navigation={navigation} />

            <Logout />
        </div>
    );
}
