import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useBadge } from "../../context/BadgeContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import BadgeList from "./List";

export default function Badge() {
    const {
        badges,
        getAllBadge,
        deleteBadge,
        publish,
        unPublish,
        getBadgeById,
    } = useBadge();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllBadge();
    }, [getAllBadge]);

    const onDeletedBadge = async (id) => {
        const res = await deleteBadge(id);

        getAllBadge();

        setToastMessage(res.message);
        setShowToast(true);
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllBadge();
    };

    const onUnPublish = async (id) => {
        const result = await unPublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllBadge();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {badges && (
                <BadgeList
                    requiredPermissions={{
                        create: Permission.CREATE_BADGE,
                        update: Permission.UPDATE_BADGE,
                        delete: Permission.DELETE_BADGE,
                        publish: Permission.PUBLISH_BADGE,
                        unPublish: Permission.UNPUBLISH_BADGE,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Badges"}
                    description={"List of all badges"}
                    addButton={"Add Badge"}
                    addButtonOnClick={() => navigate("/badges/add")}
                    deleteButtonOnClick={onDeletedBadge}
                    tableHeads={["Name", "Status"]}
                    tableData={badges.data}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    getInfo={getBadgeById}
                />
            )}
        </DashboardLayout>
    );
}
