import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useInjectScript } from "../../context/InjectScriptContext";
import DashboardLayout from "../../layouts/Dashboard";
import InjectScriptForm from "./Form";

export default function AddInjectScript() {
    const navigate = useNavigate();
    const { createInjectScript } = useInjectScript();

    const [injectScript, setInjectScript] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        const result = await createInjectScript({
            ...injectScript,
            type: injectScript.type ? injectScript.type : "Link",
        });

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/settings/inject-scripts");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="Add Inject Script" />

            <InjectScriptForm
                injectScript={injectScript}
                onNameChange={(e) => {
                    setInjectScript({ ...injectScript, name: e.target.value });
                }}
                onIDChange={(e) => {
                    setInjectScript({ ...injectScript, id: e.target.value });
                }}
                scriptTypes={["Link", "Code"]}
                onTypeChange={(e) => {
                    setInjectScript({ ...injectScript, type: e.target.value });
                }}
                onContentChange={(e) => {
                    setInjectScript({
                        ...injectScript,
                        content: e.target.value,
                    });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/settings/inject-scripts")}
            />
        </DashboardLayout>
    );
}
