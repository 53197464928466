import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useProduct } from "../../context/ProductContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import ProductList from "./List";

export default function Product() {
    const {
        products,
        getAllProduct,
        deleteProduct,
        publish,
        unPublish,
        getProductById,
    } = useProduct();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllProduct();
    }, [getAllProduct]);

    const onDeleteProduct = async (id) => {
        const res = await deleteProduct(id);

        getAllProduct();

        setToastMessage(res.message);
        setShowToast(true);
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllProduct();
    };

    const onUnPublish = async (id) => {
        const result = await unPublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllProduct();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {products && (
                <ProductList
                    requiredPermissions={{
                        create: Permission.CREATE_PRODUCT,
                        update: Permission.UPDATE_PRODUCT,
                        delete: Permission.DELETE_PRODUCT,
                        publish: Permission.PUBLISH_PRODUCT,
                        unPublish: Permission.UNPUBLISH_PRODUCT,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Products"}
                    description={"List of all products"}
                    addButton={"Add Product"}
                    addButtonOnClick={() => navigate("/products/add")}
                    deleteButtonOnClick={onDeleteProduct}
                    tableHeads={["Name", "Status"]}
                    tableData={products.data}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    getInfo={getProductById}
                />
            )}
        </DashboardLayout>
    );
}
