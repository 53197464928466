import React from "react";
import FlexibleCard from "../../components/Card/Flexible";
import InlineCheckbox from "../../components/Checkbox/Inline";

export default function RoleForm({ permissionList, setPermissionList }) {
    return (
        <>
            {permissionList?.map((module, index) => (
                <React.Fragment key={module.moduleName + index}>
                    <FlexibleCard className="my-5">
                        <InlineCheckbox
                            legendText={module.moduleName}
                            option={module.moduleName}
                            isChecked={module.permissions.every(
                                (permission) => permission.hasPermission
                            )}
                            onChange={(e) => {
                                const newPermissionList = permissionList.map(
                                    (module) => {
                                        if (
                                            module.moduleName === e.target.name
                                        ) {
                                            module.permissions =
                                                module.permissions.map(
                                                    (permission) => {
                                                        permission.hasPermission =
                                                            e.target.checked;
                                                        return permission;
                                                    }
                                                );
                                        }
                                        return module;
                                    }
                                );

                                setPermissionList(newPermissionList);
                            }}
                        />

                        <div className="px-5">
                            {module.permissions.map((permission, index) => (
                                <InlineCheckbox
                                    key={index}
                                    legendText={permission.permissionName}
                                    option={permission.permissionName}
                                    isChecked={permission.hasPermission}
                                    onChange={(e) => {
                                        const newPermissionList =
                                            permissionList.map((module) => {
                                                if (
                                                    permission.permissionName ===
                                                    e.target.name
                                                ) {
                                                    module.permissions =
                                                        module.permissions.map(
                                                            (permission) => {
                                                                if (
                                                                    permission.permissionName ===
                                                                    e.target
                                                                        .name
                                                                ) {
                                                                    permission.hasPermission =
                                                                        e.target.checked;
                                                                }
                                                                return permission;
                                                            }
                                                        );
                                                }

                                                return module;
                                            });

                                        setPermissionList(newPermissionList);
                                    }}
                                />
                            ))}
                        </div>
                    </FlexibleCard>
                </React.Fragment>
            ))}
        </>
    );
}
