import jsCookie from "js-cookie";
import React from "react";
import { testimonialHandler } from "../handler/testimonial";

const TestimonialContext = React.createContext();

export const useTestimonial = () => React.useContext(TestimonialContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function TestimonialProvider({ children }) {
    const [testimonials, setCaseStudies] = React.useState(null);

    const getAllTestimonial = React.useCallback(async (type = "MAIN") => {
        const result = await testimonialHandler.list(token, type);

        setCaseStudies(result);

        return result;
    }, []);

    const getTestimonialById = async (id, type = "MAIN") => {
        const result = await testimonialHandler.get(token, id, type);

        return result;
    };

    const updateTestimonial = async (id, data) => {
        const result = await testimonialHandler.update(token, id, data);

        return result;
    };

    const createTestimonial = async (data, type = "MAIN") => {
        const result = await testimonialHandler.create(token, data, type);

        return result;
    };

    const deleteTestimonial = async (id) => {
        const result = await testimonialHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await testimonialHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await testimonialHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        testimonials,
        getAllTestimonial,
        getTestimonialById,
        updateTestimonial,
        createTestimonial,
        deleteTestimonial,
        publish,
        unPublish,
    };

    return (
        <TestimonialContext.Provider value={contextValue}>
            {children}
        </TestimonialContext.Provider>
    );
}
