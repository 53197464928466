import { create } from "./create";
import { deleteRole } from "./delete";
import { get } from "./get";
import { list } from "./list";
import { listPermissions } from "./listPermissions";
import { update } from "./update";

export const roleHandler = {
    list,
    get,
    update,
    listPermissions,
    create,
    delete: deleteRole,
};
