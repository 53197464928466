import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import TinyMCEditor from "../../components/TinyMCEditor";
import { IMAGES } from "../../lib/slug";
import { generateImageURL, getSlugFromSentence } from "../../utils";

export default function CaseStudyForm({
    caseStudy,
    contentEditorRef,
    onNameChange,
    onDescription,
    onClick,
    onPositionChange,
    onImageChange,
    cleanImage,
    onSlugChange,
    slugRef,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="name"
                                label="Name"
                                htmlFor="name"
                                showLabel={true}
                                ariaDescribeBy="name-helper-text"
                                hint="Name of the case study"
                            >
                                <TextareaInput
                                    htmlFor="name"
                                    value={caseStudy?.name || ""}
                                    onChange={onNameChange}
                                    placeholder="Enter name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Featured Image"
                                htmlFor="featured-image"
                                showLabel={true}
                                ariaDescribeBy="featured-image-helper-text"
                                hint="This will be used as the featured image for the case study"
                            >
                                <ImageInput
                                    getData={onImageChange}
                                    image={
                                        caseStudy?.image &&
                                        generateImageURL(
                                            IMAGES,
                                            caseStudy.image
                                        )
                                    }
                                    cleanImage={cleanImage}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="description"
                                label="Description"
                                htmlFor="description"
                                showLabel={true}
                                ariaDescribeBy="description-helper-text"
                                hint="Description of the case study"
                            >
                                <TextareaInput
                                    htmlFor="description"
                                    value={caseStudy?.description || ""}
                                    onChange={onDescription}
                                    placeholder="Enter description"
                                    rows={3}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="position"
                                label="Position"
                                htmlFor="position"
                                showLabel={true}
                                ariaDescribeBy="position-helper-text"
                                hint="Position of the case study"
                            >
                                <TextareaInput
                                    htmlFor="position"
                                    value={caseStudy?.position || ""}
                                    onChange={onPositionChange}
                                    placeholder="Enter position"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                label="Content"
                                htmlFor="content"
                                showLabel={true}
                            >
                                <TinyMCEditor
                                    editorRef={contentEditorRef}
                                    placeholder="Enter content here"
                                    initialValue={caseStudy?.content || ""}
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="slug"
                                label="Slug"
                                htmlFor="slug"
                                showLabel={true}
                                ariaDescribeBy="slug-helper-text"
                                hint="This will be used in the URL"
                            >
                                <TextareaInput
                                    htmlFor="slug"
                                    onChange={onSlugChange}
                                    value={
                                        caseStudy?.slug ||
                                        (caseStudy &&
                                            caseStudy.name &&
                                            getSlugFromSentence({
                                                sentence: caseStudy?.name,
                                            })) ||
                                        ""
                                    }
                                    reference={slugRef}
                                    placeholder="Enter slug"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
