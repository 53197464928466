const {
    PencilIcon,
    SquaresPlusIcon,
    Squares2X2Icon,
} = require("@heroicons/react/24/outline");
const { default: EditJob } = require("../../pages/Job/Edit");
const { default: AddJob } = require("../../pages/Job/Add");
const { default: Jobs } = require("../../pages/Job");

module.exports = (Permission) => [
    {
        name: "Jobs",
        // href: "/jobs",
        icon: Squares2X2Icon,
        current: false,
        component: Jobs,
        hidden: true,
        permissions: [Permission.READ_JOB],
        children: [
            {
                name: "List",
                href: "/jobs",
                icon: Squares2X2Icon,
                current: false,
                component: Jobs,
                hidden: false,
                permissions: [Permission.READ_JOB],
            },
            {
                name: "Add",
                href: "/jobs/add",
                icon: SquaresPlusIcon,
                current: false,
                parent: "Jobs",
                component: AddJob,
                permissions: [Permission.CREATE_JOB],
            },
            {
                name: "Edit",
                href: "/jobs/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Jobs",
                component: EditJob,
                hidden: true,
                permissions: [Permission.UPDATE_JOB],
            },
        ],
    },
];
