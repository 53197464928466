import { XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import webpfy from "webpfy";
import { isImageInvalid } from "../../utils/image";
import LoadingInline from "../LoadingInline";

export default function ImageInput({ getData, image, cleanImage }) {
    const [base64ImageData, setBase64ImageData] = React.useState(null);
    const [showLoading, setShowLoading] = React.useState(false);

    const toBase64 = (e) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            setBase64ImageData(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const handleFileUpload = async (e) => {
        setShowLoading(true);
        const file = e.target.files[0];

        const errorMessage = await isImageInvalid(file);

        if (errorMessage) {
            alert(errorMessage);
            setShowLoading(false);

            e.target.value = null;
            return;
        }

        try {
            const { webpBlob, fileName } = await webpfy({ image: file });

            // Pass the webpBlob and fileName to the parent component
            getData(webpBlob, fileName);
            toBase64(e);
        } catch (error) {
            alert("Error converting image to WebP");
        }

        setShowLoading(false);
    };

    return (
        <div>
            {!base64ImageData && !image && (
                <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                    <label
                        htmlFor="image"
                        className={`relative ${
                            showLoading
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        } rounded-md font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2`}
                    >
                        <div className="flex justify-center rounded-md border-2 border-dashed bg-gray-50 hover:bg-gray-100 border-gray-300 px-6 pt-5 pb-6">
                            <div className="space-y-1 text-center">
                                {showLoading ? (
                                    <LoadingInline
                                        title="Processing..."
                                        text="Larger files may take around a few seconds to process."
                                    />
                                ) : (
                                    <div className="text-sm text-gray-600">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <p className="text-xs text-gray-500">
                                            PNG, JPG up to 5MB
                                        </p>

                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="hidden"
                                            accept=".png, .jpg, .jpeg .webp"
                                            disabled={showLoading}
                                            onChange={handleFileUpload}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </label>
                </div>
            )}

            {(base64ImageData || image) && (
                <>
                    <div className="relative">
                        <XCircleIcon
                            className="absolute right-0 top-0 h-8 cursor-pointer opacity-75 text-red-500 hover:text-red-700 hover:opacity-100"
                            onClick={() => {
                                setBase64ImageData(null);
                                getData(null);
                                cleanImage();
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-center w-full h-64 border-2 border-dashed border-gray-300 rounded-md">
                        <img
                            src={base64ImageData || image}
                            alt="uploaded field"
                            className="object-fit h-full"
                        />
                    </div>
                </>
            )}
        </div>
    );
}
