import axios from "axios";
import { IMAGES } from "../../lib/slug";
import jsCookie from "js-cookie";

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export const upload = async (data) => {
    try {
        const response = await axios.post(IMAGES, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
