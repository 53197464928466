import axios from "axios";
import { TESTIMONIAL } from "../../lib/slug";

export const deleteTestimonial = async (token, id) => {
    try {
        const response = await axios.delete(TESTIMONIAL, {
            params: {
                id: id,
            },

            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
