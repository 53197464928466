import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useInjectScript } from "../../context/InjectScriptContext";
import DashboardLayout from "../../layouts/Dashboard";
import InjectScriptForm from "./Form";

export default function EditInjectScripts() {
    const { getInjectScriptById, updateInjectScript } = useInjectScript();
    const { id } = useParams();
    const navigate = useNavigate();

    const [injectScript, setInjectScript] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const injectScriptResponse = await getInjectScriptById(id);

            setInjectScript(injectScriptResponse.data[0]);
        })();
    }, [getInjectScriptById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/settings/inject-scripts");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        const updateObject = injectScript;
        delete updateObject._id;
        delete updateObject.createdBy;
        delete updateObject.editors;
        delete updateObject.createdAt;
        delete updateObject.updatedAt;
        delete updateObject.editors;
        delete updateObject.__v;
        delete updateObject.status;
        delete updateObject.lastEditedBy;

        const res = await updateInjectScript(id, updateObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + injectScript?.name} />

            <InjectScriptForm
                injectScript={injectScript}
                onNameChange={(e) => {
                    setInjectScript({ ...injectScript, name: e.target.value });
                }}
                onIDChange={(e) => {
                    setInjectScript({ ...injectScript, id: e.target.value });
                }}
                scriptTypes={["Link", "Code"]}
                onTypeChange={(e) => {
                    setInjectScript({ ...injectScript, type: e.target.value });
                }}
                onContentChange={(e) => {
                    setInjectScript({
                        ...injectScript,
                        content: e.target.value,
                    });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/settings/inject-scripts")}
            />
        </DashboardLayout>
    );
}
