import axios from "axios";
import { LOGIN } from "../lib/slug";

const login = async (username, password) => {
    try {
        const response = await axios.post(
            LOGIN,
            {
                username: username,
                password: password,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default login;
