import jsCookie from "js-cookie";
import React from "react";
import { badgeHandler } from "../handler/badge";

const BadgeContext = React.createContext();

export const useBadge = () => React.useContext(BadgeContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function BadgeProvider({ children }) {
    const [badges, setBadges] = React.useState(null);

    const getAllBadge = React.useCallback(async () => {
        const result = await badgeHandler.list(token);

        setBadges(result);

        return result;
    }, []);

    const getBadgeById = async (id) => {
        const result = await badgeHandler.get(token, id);

        return result;
    };

    const updateBadge = async (id, data) => {
        const result = await badgeHandler.update(token, id, data);

        return result;
    };

    const createBadge = async (data) => {
        const result = await badgeHandler.create(token, data);

        return result;
    };

    const deleteBadge = async (id) => {
        const result = await badgeHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await badgeHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await badgeHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        badges,
        getAllBadge,
        getBadgeById,
        updateBadge,
        createBadge,
        deleteBadge,
        publish,
        unPublish,
    };

    return (
        <BadgeContext.Provider value={contextValue}>
            {children}
        </BadgeContext.Provider>
    );
}
