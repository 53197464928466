import axios from "axios";
import { TESTIMONIAL } from "../../lib/slug";

export const create = async (token, data, type) => {
    try {
        const response = await axios.post(TESTIMONIAL + "/" + type, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
