import axios from "axios";
import { CHANGE_PASSWORD } from "../../lib/slug";

export const changePassword = async (token, data) => {
    try {
        const response = await axios.patch(CHANGE_PASSWORD, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
