const { HomeIcon } = require("@heroicons/react/24/outline");
const { default: Profile } = require("../../pages/Profile");
const { default: EditProfile } = require("../../pages/Profile/Edit");

module.exports = (Permission) => {
    return [
        {
            name: "Profile",
            href: "/profile",
            icon: HomeIcon,
            current: false,
            component: Profile,
            hidden: true,
            permissions: [Permission.ALL],
        },
        {
            name: "Edit Profile",
            href: "/profile/edit",
            icon: HomeIcon,
            current: false,
            component: EditProfile,
            hidden: true,
            permissions: [Permission.ALL],
        },
    ];
};
