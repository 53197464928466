import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import AccessLogProvider from "./context/AccessLogContext";
import { AuthProvider } from "./context/AuthContext";
import { BadgeProvider } from "./context/BadgeContext";
import { BlogCategoryProvider } from "./context/BlogCategoryContext";
import { BlogProvider } from "./context/BlogContext";
import { CaseStudyProvider } from "./context/CaseStudyContext";
import { InjectScriptProvider } from "./context/InjectScriptContext";
import { JobProvider } from "./context/JobContext";
import { MenuProvider } from "./context/MenuContext";
import { OfficeLocationProvider } from "./context/OfficeLocationContext";
import { ProductProvider } from "./context/ProductContext";
import { RoleProvider } from "./context/RoleContext";
import { ShortLinkProvider } from "./context/ShortLinkContext";
import { TestimonialProvider } from "./context/TestimonialContext";
import { UserProvider } from "./context/UserContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <AuthProvider>
        <RoleProvider>
            <MenuProvider>
                <AccessLogProvider>
                    <BlogProvider>
                        <BlogCategoryProvider>
                            <JobProvider>
                                <UserProvider>
                                    <InjectScriptProvider>
                                        <OfficeLocationProvider>
                                            <BadgeProvider>
                                                <CaseStudyProvider>
                                                    <TestimonialProvider>
                                                        <ShortLinkProvider>
                                                            <ProductProvider>
                                                                <App />
                                                            </ProductProvider>
                                                        </ShortLinkProvider>
                                                    </TestimonialProvider>
                                                </CaseStudyProvider>
                                            </BadgeProvider>
                                        </OfficeLocationProvider>
                                    </InjectScriptProvider>
                                </UserProvider>
                            </JobProvider>
                        </BlogCategoryProvider>
                    </BlogProvider>
                </AccessLogProvider>
            </MenuProvider>
        </RoleProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
