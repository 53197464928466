import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useCaseStudy } from "../../context/CaseStudyContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import CaseStudyForm from "./Form";

export default function AddCaseStudy() {
    const navigate = useNavigate();
    const { createCaseStudy } = useCaseStudy();

    const [caseStudy, setCaseStudy] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const contentEditorRef = React.useRef();
    const slugRef = React.useRef();

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        const content = contentEditorRef.current.getContent();

        let createObject = {
            ...caseStudy,
            content,
            slug: caseStudy.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "case-study",
            });

            createObject = response?.key && {
                ...createObject,
                image: response.key,
            };
        }

        const result = await createCaseStudy(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/case-studies");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="Add Case Studies" />

            <CaseStudyForm
                caseStudy={caseStudy}
                contentEditorRef={contentEditorRef}
                slugRef={slugRef}
                onNameChange={(e) => {
                    setCaseStudy({ ...caseStudy, name: e.target.value });
                }}
                onDescription={(e) => {
                    setCaseStudy({ ...caseStudy, description: e.target.value });
                }}
                onSlugChange={(e) => {
                    setCaseStudy({ ...caseStudy, slug: e.target.value });
                }}
                onPositionChange={(e) => {
                    setCaseStudy({
                        ...caseStudy,
                        position: parseInt(e.target.value),
                    });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanImage={() => {
                    setCaseStudy({ ...caseStudy, image: null });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/case-studies")}
            />
        </DashboardLayout>
    );
}
