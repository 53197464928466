import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../../components/Notification/Toast";
import { useBlogCategory } from "../../../context/BlogCategoryContext";
import { imageHandler } from "../../../handler/image";
import DashboardLayout from "../../../layouts/Dashboard";
import CategoryForm from "./Form";

export default function CreateBlogCategory() {
    const { createBlogCategory } = useBlogCategory();
    const navigate = useNavigate();
    const [blogCategory, setBlogCategory] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const blogCategoryExcerptEditorRef = React.useRef();

    const [image, setImage] = React.useState(null);
    const slugRef = React.useRef();

    const submitForm = async (e) => {
        e.preventDefault();

        const excerpt = blogCategoryExcerptEditorRef.current.getContent();

        let createObject = { ...blogCategory, excerpt };

        createObject = {
            ...createObject,
            slug: createObject.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "blogCategory",
            });

            createObject = response?.key && {
                ...createObject,
                featuredImage: response.key,
            };
        }

        const result = await createBlogCategory(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/blogs/categories");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <CategoryForm
                blogCategory={blogCategory}
                onClick={submitForm}
                submitButtonText="Create"
                sectionHeader="Add Category"
                slugRef={slugRef}
                blogCategoryExcerptEditorRef={blogCategoryExcerptEditorRef}
                onNameChange={(e) => {
                    setBlogCategory({ ...blogCategory, name: e.target.value });
                }}
                onSlugChange={(e) => {
                    setBlogCategory({ ...blogCategory, slug: e.target.value });
                }}
                onFeaturedImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanFeaturedImage={() => {
                    setBlogCategory({ ...blogCategory, featuredImage: null });
                }}
                onDiscard={() => {
                    setTimeout(() => {
                        navigate(-1);
                    }, 500);
                }}
            />
        </DashboardLayout>
    );
}
