import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import routes from "../../configs/routes";
import { useMenu } from "../../context/MenuContext";
import useCurrentPath from "../../hooks/useCurrentPath";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

export default function Index({ sidebarOpen, setSidebarOpen }) {
    const { navigation, setNavigation } = useMenu();

    let nav = [];

    for (let i = 0; i < routes.length; i++) {
        nav.push({
            path: routes[i].href,
        });

        if (routes[i].children) {
            for (let j = 0; j < routes[i].children.length; j++) {
                nav.push({
                    path: routes[i].children[j].href,
                });
            }
        }
    }

    // used here instead of MenuContext because Context is in higher level than router in the app and can't use useLocation() hook in context
    const currentPath = useCurrentPath(nav);

    React.useEffect(() => {
        setNavigation((prevNavigation) =>
            prevNavigation.map((navItem) => {
                if (navItem.href === currentPath) {
                    return {
                        ...navItem,
                        current: true,
                        children: navItem.children?.map((child) => ({
                            ...child,
                            current: false,
                        })),
                    };
                } else if (navItem.children) {
                    return {
                        ...navItem,
                        children: navItem.children.map((child) =>
                            child.href === currentPath
                                ? { ...child, current: true }
                                : { ...child, current: false }
                        ),
                        current: false,
                    };
                } else {
                    return {
                        ...navItem,
                        current: false,
                    };
                }
            })
        );
    }, [currentPath, setNavigation]);

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40 md:hidden"
                    onClose={() => setSidebarOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XMarkIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>

                            <Mobile navigation={navigation} />
                        </div>
                    </Transition.Child>

                    <div className="flex-shrink-0 w-14">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            <Desktop navigation={navigation} />
        </>
    );
}
