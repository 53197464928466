import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useBadge } from "../../context/BadgeContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import { deletePropertiesFromObject } from "../../utils";
import BadgeForm from "./Form";

export default function EditBadge() {
    const { getBadgeById, updateBadge } = useBadge();
    const { id } = useParams();
    const navigate = useNavigate();

    const [badge, setBadge] = React.useState(null);
    const [image, setImage] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const badgeResponse = await getBadgeById(id);

            setBadge(badgeResponse.data[0]);
        })();
    }, [getBadgeById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/badges");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        let updateObject = badge;

        let cleanObject = deletePropertiesFromObject(updateObject, [
            "_id",
            "createdBy",
            "editors",
            "createdAt",
            "updatedAt",
            "editors",
            "__v",
            "status",
            "lastEditedBy",
        ]);

        cleanObject.position = parseInt(cleanObject.position);

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "badges",
            });

            cleanObject = response?.key && {
                ...cleanObject,
                image: response.key,
            };
        }

        const res = await updateBadge(id, cleanObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + badge?.name} />

            <BadgeForm
                badge={badge}
                onNameChange={(e) => {
                    setBadge({ ...badge, name: e.target.value });
                }}
                onAltChange={(e) => {
                    setBadge({ ...badge, alt: e.target.value });
                }}
                onPositionChange={(e) => {
                    setBadge({ ...badge, position: e.target.value });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                onCleanImage={() => {
                    setBadge({ ...badge, image: null });
                }}
                onURLChange={(e) => {
                    setBadge({ ...badge, url: e.target.value });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/badges")}
            />
        </DashboardLayout>
    );
}
