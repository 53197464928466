import jsCookie from "js-cookie";
import React from "react";
import { injectScriptHandler } from "../handler/injectScript";

const InjectScriptContext = React.createContext();

export const useInjectScript = () => React.useContext(InjectScriptContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function InjectScriptProvider({ children }) {
    const [injectScripts, setInjectScripts] = React.useState(null);

    const getAllInjectScripts = React.useCallback(async () => {
        const result = await injectScriptHandler.list(token);

        setInjectScripts(result);

        return result;
    }, []);

    const getInjectScriptById = async (id) => {
        const result = await injectScriptHandler.get(token, id);

        return result;
    };

    const updateInjectScript = async (id, data) => {
        const result = await injectScriptHandler.update(token, id, data);

        return result;
    };

    const createInjectScript = async (data) => {
        const result = await injectScriptHandler.create(token, data);

        return result;
    };

    const deleteInjectScript = async (id) => {
        const result = await injectScriptHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await injectScriptHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await injectScriptHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        injectScripts,
        getAllInjectScripts,
        getInjectScriptById,
        updateInjectScript,
        createInjectScript,
        deleteInjectScript,
        publish,
        unPublish,
    };

    return (
        <InjectScriptContext.Provider value={contextValue}>
            {children}
        </InjectScriptContext.Provider>
    );
}
