import moment from "moment";

export default function InputWithLabel({
    title,
    type,
    placeholder,
    value,
    onChange,
    className,
    min,
}) {
    return (
        <div className={className}>
            <label
                htmlFor={title}
                className="block text-sm font-medium text-gray-700"
            >
                {title}
            </label>
            <div className="">
                <input
                    type={type}
                    name={title}
                    id={title}
                    value={
                        type === "date"
                            ? moment(value)
                                  .add(1, "days")
                                  .utc()
                                  .format("YYYY-MM-DD")
                            : value
                    }
                    onChange={onChange}
                    className="block mt-1 w-full px-2 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder={placeholder}
                    min={min}
                />
            </div>
        </div>
    );
}
