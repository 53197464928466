import axios from "axios";
import { PRODUCT } from "../../lib/slug";

export const publish = async (token, id) => {
    try {
        const response = await axios.patch(
            PRODUCT + "/publish",
            {},
            {
                params: {
                    id: id,
                },

                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
