import jsCookie from "js-cookie";
import React from "react";
import { jobHandler } from "../handler/job";
const JobContext = React.createContext();

export const useJob = () => React.useContext(JobContext);
const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function JobProvider({ children }) {
    const [jobs, setJobs] = React.useState(null);

    const getJobs = React.useCallback(async () => {
        const result = await jobHandler.list(token);

        setJobs(result);

        return result;
    }, []);

    const deleteJob = async (id) => {
        const result = await jobHandler.delete(token, id);

        return result;
    };

    const getJobById = React.useCallback(async (id) => {
        const result = await jobHandler.get(token, id);

        return result;
    }, []);

    const updateJob = async (id, data) => {
        const result = await jobHandler.update(token, id, data);

        return result;
    };

    const activateJob = async (id) => {
        const result = await jobHandler.activate(token, id);

        return result;
    };

    const deActivateJob = async (id) => {
        const result = await jobHandler.deactivate(token, id);

        return result;
    };

    const createJob = async (data) => {
        const result = await jobHandler.create(token, data);

        return result;
    };

    const contextValue = {
        jobs,
        getJobs,
        deleteJob,
        getJobById,
        updateJob,
        createJob,
        activateJob,
        deActivateJob,
    };

    return (
        <JobContext.Provider value={contextValue}>
            {children}
        </JobContext.Provider>
    );
}
