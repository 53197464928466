import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useBadge } from "../../context/BadgeContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import BadgeForm from "./Form";

export default function AddBadge() {
    const navigate = useNavigate();
    const { createBadge } = useBadge();

    const [badge, setBadge] = React.useState(null);
    const [image, setImage] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        let createObject = badge;

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "badges",
            });

            createObject = response?.key && {
                ...createObject,
                image: response.key,
            };
        }

        const result = await createBadge({
            ...createObject,
            position: parseInt(createObject.position),
        });

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/badges");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="Add Badge" />

            <BadgeForm
                badge={badge}
                onNameChange={(e) => {
                    setBadge({ ...badge, name: e.target.value });
                }}
                onAltChange={(e) => {
                    setBadge({ ...badge, alt: e.target.value });
                }}
                onPositionChange={(e) => {
                    setBadge({ ...badge, position: e.target.value });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                onCleanImage={() => {
                    setBadge({ ...badge, image: null });
                }}
                onURLChange={(e) => {
                    setBadge({ ...badge, url: e.target.value });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/badges")}
            />
        </DashboardLayout>
    );
}
