import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PagingWithNumber from "../../components/Pagination/PagingWithNumber";
import { useAccessLog } from "../../context/AccessLogContext";
import { useAuth } from "../../context/AuthContext";
import DashboardLayout from "../../layouts/Dashboard";
import { hasPermission } from "../../utils/permission";
import { Permission } from "../../utils/permissionStrings";
import AccessLog from "../Dashboard/AccessLog";

export default function AccessLogList() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const { accessLogs, getAccessLogList, currentPage, totalPages, pageList } =
        useAccessLog();

    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        getAccessLogList(searchParams.get("p") || 1, 20);
    }, [searchParams, getAccessLogList]);

    return (
        <DashboardLayout>
            {hasPermission(user?.role.permissions, [
                Permission.READ_ACCESS_LOG,
            ]) && <AccessLog accessLogs={accessLogs} title="Access Logs" />}

            {totalPages > 1 && (
                <PagingWithNumber
                    linkPrefix={"/access-logs"}
                    pageList={pageList}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPrevious={() =>
                        navigate("/access-logs?p=" + (currentPage - 1))
                    }
                    onNext={() =>
                        navigate("/access-logs?p=" + (currentPage + 1))
                    }
                />
            )}
        </DashboardLayout>
    );
}
