import axios from "axios";
import jsCookie from "js-cookie";
import React from "react";
import StatsType1 from "../components/Stats/Type1";
import DashboardLayout from "../layouts/Dashboard";

import {
    PencilSquareIcon,
    Squares2X2Icon,
    UsersIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../context/AuthContext";
import { GET_STATISTICS } from "../lib/slug";

import { hasPermission } from "../utils/permission";

import { useNavigate } from "react-router-dom";
import { useAccessLog } from "../context/AccessLogContext";
import { Permission } from "../utils/permissionStrings";
import AccessLog from "./Dashboard/AccessLog";

const stats = [
    {
        id: 1,
        name: "Total Users",
        key: "totalUsers",
        stat: "XX",
        icon: UsersIcon,
        change: "3",
        changeType: "increase",
        link: "/users",
    },
    {
        id: 2,
        name: "Total Blogs",
        key: "totalBlogs",

        stat: "XX",
        icon: PencilSquareIcon,
        change: "3",
        changeType: "increase",
        link: "/blogs",
    },
    {
        id: 3,
        name: "Total Jobs",
        key: "totalJobs",

        stat: "X",
        icon: Squares2X2Icon,
        change: "10",
        changeType: "decrease",
        link: "/jobs",
    },
];

export default function Home() {
    const { user } = useAuth();
    const { accessLogs, getAccessLogList } = useAccessLog();
    const navigate = useNavigate();

    const [statistics, setStatistics] = React.useState(stats);

    React.useEffect(() => {
        (async () => {
            const response = await axios.get(GET_STATISTICS, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: jsCookie.get(
                        process.env.REACT_APP_JWT_TOKEN
                    ),
                },
            });

            setStatistics((prev) => {
                return prev.map((stat) => {
                    return {
                        ...stat,
                        stat: response.data.data[stat.key],
                    };
                });
            });
        })();
    }, []);

    React.useEffect(() => {
        getAccessLogList();
    }, [getAccessLogList]);

    return (
        <DashboardLayout>
            <div className="space-y-10">
                <StatsType1 title="Statistics" data={statistics} />

                {hasPermission(user?.role.permissions, [
                    Permission.READ_ACCESS_LOG,
                ]) &&
                    accessLogs && (
                        <AccessLog
                            accessLogs={accessLogs}
                            title="Access Logs"
                            btnName="View All"
                            btnOnClick={() => navigate("/access-logs")}
                        />
                    )}
            </div>
        </DashboardLayout>
    );
}
