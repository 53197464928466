import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useOfficeLocation } from "../../context/OfficeLocationContext";
import DashboardLayout from "../../layouts/Dashboard";
import OfficeLocationForm from "./Form";

export default function EditOfficeLocation() {
    const { getOfficeLocationById, updateOfficeLocation } = useOfficeLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [officeLocation, setOfficeLocation] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const officeLocationResponse = await getOfficeLocationById(id);

            setOfficeLocation(officeLocationResponse.data[0]);
        })();
    }, [getOfficeLocationById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/settings/offices");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        const updateObject = officeLocation;
        delete updateObject._id;
        delete updateObject.createdBy;
        delete updateObject.editors;
        delete updateObject.createdAt;
        delete updateObject.updatedAt;
        delete updateObject.editors;
        delete updateObject.__v;
        delete updateObject.status;
        delete updateObject.lastEditedBy;

        updateObject.position = parseInt(updateObject.position);

        const res = await updateOfficeLocation(id, updateObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + officeLocation?.country} />

            <OfficeLocationForm
                officeLocation={officeLocation}
                onCountryChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        country: e.target.value,
                    });
                }}
                onPositionChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        position: e.target.value,
                    });
                }}
                onGoogleMapURLChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        googleMapEmbedURL: e.target.value,
                    });
                }}
                onEmailsChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        emails: e.target.value
                            .split(",")
                            .map((email) => email.trim()),
                    });
                }}
                onPhonesChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        phones: e.target.value
                            .split(",")
                            .map((phone) => phone.trim()),
                    });
                }}
                onAddressLineChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        address: {
                            ...officeLocation.address,
                            line: e.target.value,
                        },
                    });
                }}
                onAddressAdditionalLineChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        address: {
                            ...officeLocation.address,
                            additionalLine: e.target.value,
                        },
                    });
                }}
                onAddressCityChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        address: {
                            ...officeLocation.address,
                            city: e.target.value,
                        },
                    });
                }}
                onAddressStateChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        address: {
                            ...officeLocation.address,
                            state: e.target.value,
                        },
                    });
                }}
                onAddressZipChange={(e) => {
                    setOfficeLocation({
                        ...officeLocation,
                        address: {
                            ...officeLocation.address,
                            zipCode: e.target.value,
                        },
                    });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/settings/offices")}
            />
        </DashboardLayout>
    );
}
