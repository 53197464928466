const {
    BuildingLibraryIcon,
    TableCellsIcon,
    WrenchScrewdriverIcon,
    UsersIcon,
    IdentificationIcon,
    MapPinIcon,
} = require("@heroicons/react/24/outline");
const { default: AccessLogList } = require("../../pages/AccessLogList");
const { default: Users } = require("../../pages/User");
const { default: Roles } = require("../../pages/Role");
const { default: OfficeLocation } = require("../../pages/OfficeLocation");
const { default: InjectScripts } = require("../../pages/InjectScripts");

module.exports = (Permission) => [
    {
        name: "System Administration",
        icon: BuildingLibraryIcon,
        current: false,

        hidden: false,
        permissions: [Permission.READ_BLOG],
        children: [
            {
                name: "Access Logs",
                href: "/access-logs",
                icon: TableCellsIcon,
                current: false,
                component: AccessLogList,
                hidden: false,
                permissions: [Permission.READ_ACCESS_LOG],
            },
            {
                name: "Inject Scripts (Head)",
                href: "/settings/inject-scripts",
                icon: WrenchScrewdriverIcon,
                current: false,
                component: InjectScripts,
                hidden: false,
                permissions: [Permission.READ_INJECT_SCRIPT],
            },
            {
                name: "Office Locations",
                href: "/settings/offices",
                icon: MapPinIcon,
                current: false,
                component: OfficeLocation,
                hidden: false,
                permissions: [Permission.READ_OFFICE],
            },
            {
                name: "Roles",
                href: "/roles",
                icon: IdentificationIcon,
                current: false,
                component: Roles,
                hidden: false,
                permissions: [Permission.READ_ALL_ROLES],
            },
            {
                name: "Users",
                href: "/users",
                icon: UsersIcon,
                current: false,
                component: Users,
                hidden: false,
                permissions: [Permission.READ_USER],
            },
        ],
    },
];
