import jsCookie from "js-cookie";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useBlog } from "../../context/BlogContext";
import { blogCategoryHandler } from "../../handler/blogCategory";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import { deletePropertiesFromObject } from "../../utils";
import BlogForm from "./Form";

export default function EditBlog() {
    const { getBlogById, updateBlog } = useBlog();
    const { id } = useParams();

    const [blog, setBlog] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    const [categoriesNames, setCategoriesNames] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const blogExcerptEditorRef = React.useRef();
    const blogContentEditorRef = React.useRef();
    const navigate = useNavigate();

    const slugRef = React.useRef();

    const getBlogCategories = async () => {
        const categories = await blogCategoryHandler.list(
            jsCookie.get(process.env.REACT_APP_JWT_TOKEN),
            {
                limit: 100000,
                page: 1,
            }
        );

        setCategories(categories.data);
        setCategoriesNames(categories.data.map((category) => category.name));
    };

    React.useEffect(() => {
        (async () => {
            const blogRes = await getBlogById(id);

            setBlog(blogRes);
        })();

        getBlogCategories();
    }, [getBlogById, id]);

    React.useEffect(() => {
        const _selectedCategory = categories.find(
            (category) => category._id === blog?.category
        );

        setSelectedCategory(_selectedCategory ? _selectedCategory.name : null);
    }, [blog, categories]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1000);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/blogs");
            }, 1500);
        }
    });

    const submitForm = async (e) => {
        e.preventDefault();

        const content = blogContentEditorRef.current.getContent();
        const excerpt = blogExcerptEditorRef.current.getContent();

        let updateObject = { ...blog, content, excerpt };

        const cleanObj = deletePropertiesFromObject(updateObject, [
            "_id",
            "updatedAt",
            "createdAt",
            "editors",
            "lastEditedBy",
            "author",
            "publishedBy",
            "share",
            "view",
            "comments",
            "unpublishedBy",
            "status",
        ]);

        /**
         * Delete status from createObject
         *
         * Previously, status update was acceptable in blog update. However, in later date, we started depending on the status of the blog to determine whether to show the blog or not. \
         * We have separate route for updating status now.
         * So that, only those with permission can change the status.
         * However, this delete has to happen in-order to keep previously created blogs update working.
         */

        updateObject = {
            ...cleanObj,
            slug: cleanObj.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "blog",
            });

            updateObject = response?.key && {
                ...updateObject,
                featuredImage: response.key,
            };
        }

        const result = await updateBlog(blog._id, updateObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {blog && (
                <BlogForm
                    blog={blog}
                    categories={categoriesNames}
                    selectedCategory={selectedCategory}
                    onClick={submitForm}
                    submitButtonText="Update"
                    sectionHeader="Edit Blog"
                    blogContentEditorRef={blogContentEditorRef}
                    blogExcerptEditorRef={blogExcerptEditorRef}
                    onCategoryChange={(e) => {
                        const category = categories.find(
                            (category) => category.name === e.target.value
                        );

                        setBlog({
                            ...blog,
                            category: category._id,
                        });
                    }}
                    onVisibilityChange={(e) => {
                        setBlog({ ...blog, visibility: e.target.value });
                    }}
                    onTitleChange={(e) => {
                        setBlog({ ...blog, title: e.target.value });
                    }}
                    onSlugChange={(e) => {
                        setBlog({ ...blog, slug: e.target.value });
                    }}
                    onTagsChange={(e) => {
                        setBlog({
                            ...blog,
                            tags: e.target.value
                                .split(",")
                                .map((tag) => tag.trim()),
                        });
                    }}
                    onFeaturedImageChange={(image, name) => {
                        setImage({ file: image, name: name });
                    }}
                    cleanFeaturedImage={() => {
                        setBlog({ ...blog, featuredImage: null });
                    }}
                    onDiscard={() => {
                        setTimeout(() => {
                            navigate(-1);
                        }, 500);
                    }}
                />
            )}
        </DashboardLayout>
    );
}
