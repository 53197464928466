export const ROOT = process.env.REACT_APP_API;
export const LOGIN = `${ROOT}/auth/login`;
export const LOGOUT = `${ROOT}/logout`;

export const BLOG = `${ROOT}/blogs`;
export const GET_ALL_BLOGS = `${BLOG}/private`;
export const GET_BLOG = `${BLOG}/:id`;
export const CREATE_BLOG = `${BLOG}`;
export const UPDATE_BLOG = `${BLOG}/:id`;
export const DELETE_BLOG = `${BLOG}/:id`;
export const UNPUBLISH_BLOG = `${BLOG}/unpublish/:id`;

export const LEAVE_APPLICATION = `${ROOT}/hr/leave`;
export const GET_ALL_LEAVE_APPLICATIONS = `${LEAVE_APPLICATION}`;
export const LEAVE_APPLICATIONS_ARCHIVE = `${LEAVE_APPLICATION}/archive`;
export const GET_LEAVE_APPLICATION = `${LEAVE_APPLICATION}`;
export const CREATE_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/apply`;
export const UPDATE_LEAVE_APPLICATON = `${LEAVE_APPLICATION}`;
export const DELETE_LEAVE_APPLICATION = `${LEAVE_APPLICATION}`;
export const DISAPPROVE_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/disapprove`;
export const APPROVE_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/approve`;
export const RECOMMEND_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/recommend`;
export const NOT_RECOMMEND_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/not-recommend`;
export const CANCEL_LEAVE_APPLICATION = `${LEAVE_APPLICATION}/cancel`;

export const USER = `${ROOT}/users`;
export const GET_PROFILE = `${USER}/profile`;

export const ROLE = `${ROOT}/roles`;
export const GET_ALL_ROLES = `${ROLE}/list`;
export const GET_ALL_PERMISSIONS = `${ROLE}/permissions`;

export const JOB = `${ROOT}/jobs`;
export const READ_PRIVATE_JOB = `${JOB}/private`;

export const IMAGES = `${ROOT}/images`;

export const CHANGE_PASSWORD = `${USER}/update/password`;

export const GET_STATISTICS = `${ROOT}/dashboard/getStats`;

export const BLOG_CATEGORY = `${ROOT}/blogs/categories`;

export const GET_ACCESS_LOGS = `${ROOT}/access-logs`;

export const INJECT_SCRIPT = `${ROOT}/inject-scripts`;
export const OFFICE = `${ROOT}/offices`;
export const BADGE = `${ROOT}/badges`;
export const CASE_STUDY = `${ROOT}/case-studies`;
export const TESTIMONIAL = `${ROOT}/testimonials`;
export const SHORT_LINK = `${ROOT}/short-links`;

export const SHORT_LINK_HOST = "https://x.zaagsys.com/";

export const PRODUCT = `${ROOT}/products`;
