const {
    PencilIcon,
    DocumentCheckIcon,
} = require("@heroicons/react/24/outline");
const { default: EditTestimonial } = require("../../pages/Testimonial/Edit");
const { default: AddTestimonial } = require("../../pages/Testimonial/Add");
const { default: Testimonial } = require("../../pages/Testimonial");

module.exports = (Permission) => [
    {
        name: "Testimonial",
        // href: "/testimonials",
        icon: DocumentCheckIcon,
        current: false,
        component: Testimonial,
        hidden: true,
        permissions: [Permission.READ_TESTIMONIAL],
        children: [
            {
                name: "Add",
                href: "/testimonials/add",
                icon: PencilIcon,
                current: false,
                parent: "Testimonial",
                component: AddTestimonial,
                hidden: true,
                permissions: [Permission.CREATE_TESTIMONIAL],
            },
            {
                name: "Edit",
                href: "/testimonials/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Testimonial",
                component: EditTestimonial,
                hidden: true,
                permissions: [Permission.UPDATE_TESTIMONIAL],
            },
        ],
    },
];
