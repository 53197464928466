import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useJob } from "../../context/JobContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import JobForm from "./JobForm";

export default function EditJob() {
    const { getJobById, updateJob } = useJob();
    const { id } = useParams();

    const [job, setJob] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const jobContentEditorRef = React.useRef();
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            const jobRes = await getJobById(id);

            setJob(jobRes.data[0]);
        })();
    }, [getJobById, id]);

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 10000);

            navigate(-1);
        }
    });

    const cleanFeaturedImage = () => {
        setJob({ ...job, featuredImage: null });
    };

    const submitForm = async (e) => {
        e.preventDefault();

        const description = jobContentEditorRef.current.getContent();

        let updateObject = { ...job, description, category: job.category._id };
        delete updateObject._id;
        delete updateObject.updatedAt;
        delete updateObject.createdAt;
        delete updateObject.editors;
        delete updateObject.lastEditedBy;
        delete updateObject.createdBy;
        delete updateObject.__v;
        delete updateObject.active;
        delete updateObject.count;

        updateObject = {
            ...updateObject,
            noOfVacancies: updateObject.noOfVacancies.toString(),
            // active: updateObject.active.toLowerCase() === "true",
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "job",
            });

            updateObject = response?.key && {
                ...updateObject,
                featuredImage: response.key,
            };
        }

        const result = await updateJob(job._id, updateObject);

        setToastMessage(result.message);
        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {job && (
                <JobForm
                    job={job}
                    onClick={submitForm}
                    submitButtonText="Update"
                    sectionHeader="Edit Job"
                    jobContentEditorRef={jobContentEditorRef}
                    onTitleChange={(e) => {
                        setJob({ ...job, title: e.target.value });
                    }}
                    onSlugChange={(e) => {
                        setJob({ ...job, slug: e.target.value });
                    }}
                    onDeadlineChange={(e) => {
                        setJob({ ...job, deadline: e.target.value });
                    }}
                    onLocationChange={(e) => {
                        setJob({ ...job, nature: e.target.value });
                    }}
                    onTypeChange={(e) => {
                        setJob({ ...job, type: e.target.value });
                    }}
                    onExperienceChange={(e) => {
                        setJob({ ...job, experience: e.target.value });
                    }}
                    onNoOfVacanciesChange={(e) => {
                        setJob({ ...job, noOfVacancies: e.target.value });
                    }}
                    onActiveChange={(e) => {
                        setJob({ ...job, active: e.target.value });
                    }}
                    onFeaturedImageChange={(image, name) => {
                        setImage({ file: image, name: name });
                    }}
                    cleanFeaturedImage={cleanFeaturedImage}
                    onDiscard={() => {
                        setTimeout(() => {
                            navigate(-1);
                        }, 500);
                    }}
                />
            )}
        </DashboardLayout>
    );
}
