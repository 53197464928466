export default function SimpleDropdown({
    label,
    options,
    defaultValue,
    selected,
    onSelect,
}) {
    return (
        <div>
            <label
                htmlFor={label}
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <select
                id={label}
                name={label}
                onChange={onSelect}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={selected || ""}
            >
                <option disabled value={""}>
                    {defaultValue}
                </option>
                {options.map((option) => {
                    return (
                        <option key={option} value={option}>
                            {`${option}`}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}
