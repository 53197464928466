const { PencilIcon, CubeIcon } = require("@heroicons/react/24/outline");
const { default: AddProduct } = require("../../pages/Product/Add");
const { default: EditProduct } = require("../../pages/Product/Edit");
const { default: Product } = require("../../pages/Product");

module.exports = (Permission) => [
    {
        name: "Products",
        // href: "/products",
        icon: CubeIcon,
        current: false,
        component: Product,
        hidden: true,
        permissions: [Permission.READ_PRODUCT],
        children: [
            {
                name: "Add",
                href: "/products/add",
                icon: PencilIcon,
                current: false,
                parent: "Products",
                component: AddProduct,
                hidden: true,
                permissions: [Permission.CREATE_PRODUCT],
            },
            {
                name: "Edit",
                href: "/products/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Products",
                component: EditProduct,
                hidden: true,
                permissions: [Permission.UPDATE_PRODUCT],
            },
        ],
    },
];
