import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useJob } from "../../context/JobContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import JobList from "./List";

export default function Jobs() {
    const { getJobs, jobs, deleteJob, activateJob, deActivateJob } = useJob();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        getJobs();
    }, [getJobs]);

    const onDelete = async (id) => {
        const res = await deleteJob(id);

        getJobs();

        setToastMessage(res.message);
        setShowToast(true);
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    const onActive = async (id) => {
        const result = await activateJob(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getJobs();
    };

    const onDeactivate = async (id) => {
        const result = await deActivateJob(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getJobs();
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {jobs && (
                <JobList
                    requiredPermissions={{
                        create: Permission.CREATE_JOB,
                        update: Permission.UPDATE_JOB,
                        delete: Permission.DELETE_JOB,
                        activate: Permission.ACTIVATE_JOB,
                        deactivate: Permission.DEACTIVATE_JOB,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Jobs"}
                    description={"List of all jobs"}
                    addButton={"Add Job"}
                    addButtonOnClick={() => navigate("/jobs/add")}
                    deleteButtonOnClick={onDelete}
                    tableHeads={["Title", "Type", "Nature", "Deadline"]}
                    tableData={jobs.data}
                    tableDataDisplayLimit={3}
                    onActive={onActive}
                    onDeactivate={onDeactivate}
                />
            )}
        </DashboardLayout>
    );
}
