import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useProduct } from "../../context/ProductContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import { deletePropertiesFromObject } from "../../utils";
import ProductForm from "./Form";

export default function EditProduct() {
    const { getProductById, updateProduct } = useProduct();
    const { id } = useParams();
    const navigate = useNavigate();

    const [product, setProduct] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const slugRef = React.useRef();

    React.useEffect(() => {
        (async () => {
            const productResponse = await getProductById(id);

            setProduct(productResponse.data[0]);
        })();
    }, [getProductById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/products");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        // const content = contentEditorRef.current.getContent();

        let updateObject = {
            ...product,
            slug: product.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "products",
            });

            updateObject = response?.key && {
                ...updateObject,
                image: response.key,
            };
        }

        const cleanedObject = deletePropertiesFromObject(updateObject, [
            "_id",
            "createdBy",
            "editors",
            "createdAt",
            "updatedAt",
            "editors",
            "__v",
            "status",
            "lastEditedBy",
            "details",
        ]);

        const res = await updateProduct(id, cleanedObject);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title={"Edit: " + product?.name} />

            <ProductForm
                product={product}
                slugRef={slugRef}
                onNameChange={(e) => {
                    setProduct({ ...product, name: e.target.value });
                }}
                onDescription={(e) => {
                    setProduct({ ...product, description: e.target.value });
                }}
                onSlugChange={(e) => {
                    setProduct({ ...product, slug: e.target.value });
                }}
                onPositionChange={(e) => {
                    setProduct({
                        ...product,
                        position: parseInt(e.target.value),
                    });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanImage={() => {
                    setProduct({ ...product, image: null });
                }}
                submitButtonText="Update"
                onClick={onSubmit}
                onDiscard={() => navigate("/products")}
            />
        </DashboardLayout>
    );
}
