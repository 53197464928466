import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useRole } from "../../context/RoleContext";
import { useUser } from "../../context/UserContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import UserForm from "./Form";

export default function AddUser() {
    const { createUser } = useUser();
    const { getAllRoles, roles } = useRole();

    const [user, setUser] = React.useState(null);
    const [roleAliases, setRoleAliases] = React.useState([]);
    const [selectedRoleAlias, setSelectedRoleAlias] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        getAllRoles();
    }, [getAllRoles]);

    React.useEffect(() => {
        if (roles) {
            const aliases = roles.data.map((role) => role.alias);

            setRoleAliases(aliases);
        }

        // find the role alias of the user
        if (user?.role && roles) {
            const role = roles.data.find((role) => role._id === user.role._id);

            setSelectedRoleAlias(role.alias);
        }
    }, [roles, user]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/users");
            }, 2000);
        }
    });

    const submitForm = async (e) => {
        e.preventDefault();

        let createObject = user.role
            ? { ...user, role: user.role._id }
            : { ...user };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "user",
            });

            createObject = response?.key && {
                ...createObject,
                profileImage: response.key,
            };
        }

        const result = await createUser(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {
                <UserForm
                    user={user}
                    roleAliases={roleAliases}
                    selectedRoleAlias={selectedRoleAlias}
                    onClick={submitForm}
                    submitButtonText="Create"
                    sectionHeader="Add User"
                    onNameChange={(e) => {
                        setUser({ ...user, name: e.target.value });
                    }}
                    onDesignationChange={(e) => {
                        setUser({ ...user, designation: e.target.value });
                    }}
                    onUsernameChange={(e) => {
                        setUser({ ...user, username: e.target.value });
                    }}
                    onProfileImageChange={(image, name) => {
                        setImage({ file: image, name: name });
                    }}
                    onRoleAliasChange={(e) => {
                        setSelectedRoleAlias(e.target.value);

                        // get the _id of the role
                        const role = roles.data.find(
                            (role) => role.alias === e.target.value
                        );

                        setUser({ ...user, role: role });
                    }}
                    onPasswordChange={(e) => {
                        setUser({ ...user, password: e.target.value });
                    }}
                    cleanProfileImage={() => {
                        setUser({ ...user, profileImage: null });
                    }}
                    onDiscard={() => {
                        setTimeout(() => {
                            navigate(-1);
                        }, 500);
                    }}
                />
            }
        </DashboardLayout>
    );
}
