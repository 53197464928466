import React from "react";
import TertiaryButton from "../Button/Tertiary";
import FlexOneLineJustifyBetween from "../Design/OneLiner/FlexJustifyBetween";

export default function ContainerWithTitleAndButton({
    title,
    btnName,
    btnOnClick,
    children,
}) {
    return (
        <div className="space-y-5">
            <FlexOneLineJustifyBetween>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                </h3>

                {btnName && (
                    <TertiaryButton name={btnName} onClick={btnOnClick} />
                )}
            </FlexOneLineJustifyBetween>

            {children}
        </div>
    );
}
