import { activate } from "./active";
import { create } from "./create";
import { deactivate } from "./deactive";
import { deleteJob } from "./delete";
import { get } from "./get";
import { list } from "./list";
import { update } from "./update";

export const jobHandler = {
    list,
    delete: deleteJob,
    get,
    update,
    create,
    activate,
    deactivate,
};
