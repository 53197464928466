import axios from "axios";
import { BLOG_CATEGORY } from "../../lib/slug";

export const list = async (token, { limit = 10, page = 1 }) => {
    try {
        const response = await axios.get(BLOG_CATEGORY, {
            params: {
                limit: limit,
                page: page,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
