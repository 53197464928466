import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import ImageInput from "../../components/Input/Image";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import { IMAGES } from "../../lib/slug";
import { generateImageURL } from "../../utils";

export default function ProfileForm({
    user,
    onNameChange,
    onDesignationChange,
    onClick,
    onProfileImageChange,
    cleanProfileImage,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                label="Profile Image"
                                htmlFor="profile-image"
                                showLabel={true}
                                ariaDescribeBy="profile-image-helper-text"
                                hint="This will be used as the profile image for the user"
                            >
                                <ImageInput
                                    getData={onProfileImageChange}
                                    image={
                                        user?.profileImage &&
                                        generateImageURL(
                                            IMAGES,
                                            user.profileImage
                                        )
                                    }
                                    cleanImage={cleanProfileImage}
                                />
                            </LabelAndHelperWrapper>
                            <LabelAndHelperWrapper
                                key="name"
                                label="Name"
                                htmlFor="name"
                                showLabel={true}
                                ariaDescribeBy="name-helper-text"
                                hint="Name of the user"
                            >
                                <TextareaInput
                                    htmlFor="name"
                                    value={user?.name || ""}
                                    onChange={onNameChange}
                                    placeholder="Enter name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="designation"
                                label="Designation"
                                htmlFor="designation"
                                showLabel={true}
                                ariaDescribeBy="designation-helper-text"
                                hint="Designation of the user"
                            >
                                <TextareaInput
                                    htmlFor="designation"
                                    value={user?.designation || ""}
                                    onChange={onDesignationChange}
                                    placeholder="Enter designation"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
