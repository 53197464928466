const {
    PencilIcon,
    ClipboardDocumentCheckIcon,
} = require("@heroicons/react/24/outline");
const { default: EditCaseStudy } = require("../../pages/CaseStudy/Edit");
const { default: AddCaseStudy } = require("../../pages/CaseStudy/Add");
const { default: CaseStudy } = require("../../pages/CaseStudy");

module.exports = (Permission) => [
    {
        name: "Case Study",
        // href: "/case-studies",
        icon: ClipboardDocumentCheckIcon,
        current: false,
        component: CaseStudy,
        hidden: true,
        permissions: [Permission.READ_CASE_STUDIES],
        children: [
            {
                name: "Add",
                href: "/case-studies/add",
                icon: PencilIcon,
                current: false,
                parent: "Case Study",
                component: AddCaseStudy,
                hidden: true,
                permissions: [Permission.CREATE_CASE_STUDIES],
            },
            {
                name: "Edit",
                href: "/case-studies/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Case Study",
                component: EditCaseStudy,
                hidden: true,
                permissions: [Permission.UPDATE_CASE_STUDIES],
            },
        ],
    },
];
