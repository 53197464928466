import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useTestimonial } from "../../context/TestimonialContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import TestimonialList from "./List";

export default function Testimonial() {
    const {
        testimonials,
        getAllTestimonial,
        deleteTestimonial,
        publish,
        unPublish,
        getTestimonialById,
    } = useTestimonial();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllTestimonial();
    }, [getAllTestimonial]);

    const onDeleteTestimonial = async (id) => {
        const res = await deleteTestimonial(id);

        getAllTestimonial();

        setToastMessage(res.message);
        setShowToast(true);
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllTestimonial();
    };

    const onUnPublish = async (id) => {
        const result = await unPublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllTestimonial();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {testimonials && (
                <TestimonialList
                    requiredPermissions={{
                        create: Permission.CREATE_TESTIMONIAL,
                        update: Permission.UPDATE_TESTIMONIAL,
                        delete: Permission.DELETE_TESTIMONIAL,
                        publish: Permission.PUBLISH_TESTIMONIAL,
                        unPublish: Permission.UNPUBLISH_TESTIMONIAL,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Testimonial"}
                    description={"List of all testimonials"}
                    addButton={"Add Testimonial"}
                    addButtonOnClick={() => navigate("/testimonials/add")}
                    onEditButtonClick={(id) =>
                        navigate(`/testimonials/edit/${id}`)
                    }
                    deleteButtonOnClick={onDeleteTestimonial}
                    tableHeads={["Name", "Status"]}
                    tableData={testimonials.data}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    getInfo={getTestimonialById}
                />
            )}
        </DashboardLayout>
    );
}
