import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../utils/permission";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function RoleList({
    title,
    description,
    addButton,
    addButtonOnClick,
    deleteButtonOnClick,
    tableHeads,
    tableData,
    requiredPermissions,
    userPermissions,
}) {
    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const navigate = useNavigate();

    useLayoutEffect(() => {
        const isIndeterminate =
            selectedData.length > 0 && selectedData.length < tableData.length;

        setChecked(selectedData.length === tableData.length);
        setIndeterminate(isIndeterminate);

        checkbox.current.indeterminate = isIndeterminate;
    }, [selectedData, tableData.length]);

    function toggleAll() {
        setSelectedData(checked || indeterminate ? [] : tableData);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        {title}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">{description}</p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        disabled={
                            !hasPermission(userPermissions, [
                                requiredPermissions.create,
                            ])
                        }
                        onClick={addButtonOnClick}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto disabled:cursor-not-allowed disabled:opacity-30"
                    >
                        {addButton}
                    </button>
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            {selectedData.length > 0 && (
                                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                                    <button
                                        type="button"
                                        disabled={
                                            !hasPermission(userPermissions, [
                                                requiredPermissions.delete,
                                            ])
                                        }
                                        onClick={() => {
                                            selectedData.map((data) =>
                                                deleteButtonOnClick(data._id)
                                            );

                                            setSelectedData([]);
                                        }}
                                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                    >
                                        Delete{" "}
                                        {selectedData.length > 1 ? "all" : ""}
                                    </button>
                                </div>
                            )}
                            <table className="min-w-full table-fixed divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="relative w-12 px-6 sm:w-16 sm:px-8"
                                        >
                                            <input
                                                disabled={
                                                    !hasPermission(
                                                        userPermissions,
                                                        [
                                                            requiredPermissions.delete,
                                                        ]
                                                    )
                                                }
                                                type="checkbox"
                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 disabled:cursor-not-allowed disabled:opacity-30"
                                                ref={checkbox}
                                                checked={checked}
                                                onChange={toggleAll}
                                            />
                                        </th>

                                        {tableHeads.map((tableHead, index) => (
                                            <th
                                                scope="col"
                                                key={index}
                                                className={
                                                    index === 0
                                                        ? "min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                                        : "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                }
                                            >
                                                {tableHead}
                                            </th>
                                        ))}

                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {tableData.map((row) => (
                                        <tr
                                            key={row._id}
                                            className={
                                                selectedData.includes(row)
                                                    ? "bg-gray-50"
                                                    : undefined
                                            }
                                        >
                                            <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                                {selectedData.includes(row) && (
                                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                                )}
                                                <input
                                                    disabled={
                                                        !hasPermission(
                                                            userPermissions,
                                                            [
                                                                requiredPermissions.delete,
                                                            ]
                                                        )
                                                    }
                                                    type="checkbox"
                                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 disabled:cursor-not-allowed disabled:opacity-30"
                                                    value={row._id}
                                                    checked={selectedData.includes(
                                                        row
                                                    )}
                                                    onChange={(e) =>
                                                        setSelectedData(
                                                            e.target.checked
                                                                ? [
                                                                      ...selectedData,
                                                                      row,
                                                                  ]
                                                                : selectedData.filter(
                                                                      (p) =>
                                                                          p !==
                                                                          row
                                                                  )
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td
                                                className={classNames(
                                                    "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                                    selectedData.includes(row)
                                                        ? "text-indigo-600"
                                                        : "text-gray-900"
                                                )}
                                            >
                                                {row.alias}
                                            </td>

                                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                {/* // TODO: fix static link; high
                                                coupling */}
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            `/roles/edit/${row._id}`
                                                        )
                                                    }
                                                    disabled={
                                                        !hasPermission(
                                                            userPermissions,
                                                            [
                                                                requiredPermissions.update,
                                                            ]
                                                        )
                                                    }
                                                    className="text-indigo-600 hover:text-indigo-900 disabled:cursor-not-allowed disabled:opacity-30"
                                                >
                                                    Edit
                                                    <span className="sr-only">
                                                        , {row.name}
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
