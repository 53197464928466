import React from "react";
import BasicPanel from "../../components/Panels/Basic";
import RightBottom from "../../components/RightBottom";
import { useAuth } from "../../context/AuthContext";
import DashboardLayout from "../../layouts/Dashboard";
import ProfileHeader from "./Header";

export default function Profile() {
    const { user } = useAuth();

    return (
        <DashboardLayout>
            <BasicPanel>
                <ProfileHeader user={user} />
            </BasicPanel>

            <RightBottom>
                <h1 className="opacity-20 px-4 sm:px-8 py-10">
                    more options coming soon
                </h1>
            </RightBottom>
        </DashboardLayout>
    );
}
