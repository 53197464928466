import jsCookie from "js-cookie";
import React from "react";
import { blogCategoryHandler } from "../handler/blogCategory";
const BlogCategoryContext = React.createContext();

export const useBlogCategory = () => React.useContext(BlogCategoryContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function BlogCategoryProvider({ children }) {
    const [blogCategories, setBlogCategories] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [limit] = React.useState(10);
    const [pageList, setPageList] = React.useState([]);

    const getBlogCategories = React.useCallback(
        async (page) => {
            const result = await blogCategoryHandler.list(token, {
                page: page,
                limit: limit,
            });

            setBlogCategories(result.data);
            setTotalPages(result.totalPage);
            setCurrentPage(parseInt(result.page));
            setPageList(
                Array.from({ length: result.totalPage }, (v, k) => k + 1)
            );

            return result;
        },
        [limit]
    );

    const deleteBlogCategory = async (id) => {
        const result = await blogCategoryHandler.delete(token, id);

        return result;
    };

    const getBlogCategoryById = React.useCallback(async (id) => {
        const result = await blogCategoryHandler.get(token, id);

        return result;
    }, []);

    const updateBlogCategory = async (id, data) => {
        const result = await blogCategoryHandler.update(token, id, data);

        return result;
    };

    const createBlogCategory = async (data) => {
        const result = await blogCategoryHandler.create(token, data);

        return result;
    };

    const contextValue = {
        blogCategories,
        getBlogCategories,
        deleteBlogCategory,
        getBlogCategoryById,
        updateBlogCategory,
        createBlogCategory,
        currentPage,
        totalPages,
        pageList,
    };

    return (
        <BlogCategoryContext.Provider value={contextValue}>
            {children}
        </BlogCategoryContext.Provider>
    );
}
