const { PencilIcon, LinkIcon } = require("@heroicons/react/24/outline");
const { default: EditShortLink } = require("../../pages/URLShortner/Edit");
const { default: AddShortLink } = require("../../pages/URLShortner/Add");
const { default: URLShortner } = require("../../pages/URLShortner");

module.exports = (Permission) => [
    {
        name: "URL Shortner",
        // href: "/short-links",
        icon: LinkIcon,
        current: false,
        component: URLShortner,
        hidden: false,
        permissions: [Permission.READ_SHORT_LINK],
        children: [
            {
                name: "List",
                href: "/short-links",
                icon: LinkIcon,
                current: false,
                component: URLShortner,
                hidden: false,
                permissions: [Permission.READ_SHORT_LINK],
            },
            {
                name: "Add",
                href: "/short-links/add",
                icon: PencilIcon,
                current: false,
                parent: "Roles",
                component: AddShortLink,
                permissions: [Permission.CREATE_SHORT_LINK],
            },
            {
                name: "Edit",
                href: "/short-links/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Roles",
                component: EditShortLink,
                hidden: true,
                permissions: [Permission.UPDATE_SHORT_LINK],
            },
        ],
    },
];
