import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useProduct } from "../../context/ProductContext";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import ProductForm from "./Form";

export default function AddProduct() {
    const navigate = useNavigate();
    const { createProduct } = useProduct();

    const [caseStudy, setProduct] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const [image, setImage] = React.useState(null);

    const slugRef = React.useRef();

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        // const content = contentEditorRef.current.getContent();

        let createObject = {
            ...caseStudy,
            // content,
            slug: caseStudy.slug || slugRef.current.value,
        };

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "products",
            });

            createObject = response?.key && {
                ...createObject,
                image: response.key,
            };
        }

        const result = await createProduct(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/products");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="Add Product" />

            <ProductForm
                caseStudy={caseStudy}
                slugRef={slugRef}
                onNameChange={(e) => {
                    setProduct({ ...caseStudy, name: e.target.value });
                }}
                onDescription={(e) => {
                    setProduct({ ...caseStudy, description: e.target.value });
                }}
                onSlugChange={(e) => {
                    setProduct({ ...caseStudy, slug: e.target.value });
                }}
                onPositionChange={(e) => {
                    setProduct({
                        ...caseStudy,
                        position: parseInt(e.target.value),
                    });
                }}
                onImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanImage={() => {
                    setProduct({ ...caseStudy, image: null });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/products")}
            />
        </DashboardLayout>
    );
}
