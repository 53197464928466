const { PencilSquareIcon, PencilIcon } = require("@heroicons/react/24/outline");
const { default: Blogs } = require("../../pages/Blog");
const { default: CreateBlog } = require("../../pages/Blog/Create");
const { default: EditBlog } = require("../../pages/Blog/Edit");
const {
    default: CreateBlogCategory,
} = require("../../pages/Blog/Category/Create");
const {
    default: EditBlogCategories,
} = require("../../pages/Blog/Category/Edit");

module.exports = (Permission) => [
    {
        name: "Blogs",
        icon: PencilSquareIcon,
        current: false,
        component: Blogs,

        hidden: true,
        permissions: [Permission.READ_BLOG],
        children: [
            {
                name: "Add",
                href: "/blogs/add",
                icon: PencilIcon,
                current: false,
                parent: "Blogs",
                hidden: true,
                component: CreateBlog,
                permissions: [Permission.CREATE_BLOG],
            },
            {
                name: "Edit",
                href: "/blogs/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Blogs",
                component: EditBlog,
                hidden: true,
                permissions: [Permission.UPDATE_BLOG],
            },

            {
                name: "Add",
                href: "/blogs/categories/add",
                icon: PencilIcon,
                current: false,
                parent: "Blog Category",
                component: CreateBlogCategory,
                hidden: true,
                permissions: [Permission.CREATE_BLOG_CATEGORY],
            },
            {
                name: "Edit",
                href: "/blogs/categories/edit/:id",
                icon: PencilIcon,
                current: false,
                parent: "Blog",
                component: EditBlogCategories,
                hidden: true,
                permissions: [Permission.UPDATE_BLOG_CATEGORY],
            },
        ],
    },
];
