import { create } from "./create";
import { deleteRole } from "./delete";
import { get } from "./get";
import { list } from "./list";
import { publish } from "./publish";
import { unPublish } from "./unpublish";
import { update } from "./update";

export const injectScriptHandler = {
    list,
    get,
    update,
    create,
    delete: deleteRole,
    publish,
    unPublish,
};
