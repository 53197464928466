import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useCaseStudy } from "../../context/CaseStudyContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import CaseStudyList from "./List";

export default function CaseStudy() {
    const {
        caseStudies,
        getAllCaseStudy,
        deleteCaseStudy,
        publish,
        unPublish,
        getCaseStudyById,
    } = useCaseStudy();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllCaseStudy();
    }, [getAllCaseStudy]);

    const onDeleteCaseStudy = async (id) => {
        const res = await deleteCaseStudy(id);

        getAllCaseStudy();

        setToastMessage(res.message);
        setShowToast(true);
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllCaseStudy();
    };

    const onUnPublish = async (id) => {
        const result = await unPublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllCaseStudy();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {caseStudies && (
                <CaseStudyList
                    requiredPermissions={{
                        create: Permission.CREATE_CASE_STUDIES,
                        update: Permission.UPDATE_CASE_STUDIES,
                        delete: Permission.DELETE_CASE_STUDIES,
                        publish: Permission.PUBLISH_CASE_STUDIES,
                        unPublish: Permission.UNPUBLISH_CASE_STUDIES,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"Case Study"}
                    description={"List of all case studies"}
                    addButton={"Add Case Study"}
                    addButtonOnClick={() => navigate("/case-studies/add")}
                    deleteButtonOnClick={onDeleteCaseStudy}
                    tableHeads={["Name", "Status"]}
                    tableData={caseStudies.data}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    getInfo={getCaseStudyById}
                />
            )}
        </DashboardLayout>
    );
}
