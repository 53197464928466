import { imageForm } from "../../utils/formfy";
import { upload } from "./upload";

export const uploadV2 = async ({ image, folder }) => {
    const formData = imageForm({ image, folder });

    const response = await upload(formData);

    return response;
};
