import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useRole } from "../../context/RoleContext";
import DashboardLayout from "../../layouts/Dashboard";
import RoleForm from "./Form";

export default function EditRole() {
    const { getRoleById, updateRole } = useRole();
    const { id } = useParams();
    const navigate = useNavigate();

    const [role, setRole] = React.useState(null);
    const [permissionList, setPermissionList] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const roleRes = await getRoleById(id);

            setRole(roleRes);
            setPermissionList(roleRes.permissionList);
        })();
    }, [getRoleById, id]);

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/roles");
            }, 2000);
        }
    });

    const onSubmit = async () => {
        const data = {
            alias: role.alias,
            permissionList: permissionList,
        };

        const res = await updateRole(id, data);

        setToastMessage(res.message);

        res.status && res.status === 400 ? setIsError(true) : setIsError(false);

        setShowToast(true);
    };

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons
                title={"Edit: " + role?.alias}
                button1="Cancel"
                button2="Save"
                button1OnClick={() => navigate(-1)}
                button2OnClick={() => onSubmit()}
            />

            <RoleForm
                permissionList={permissionList}
                setPermissionList={setPermissionList}
            />
        </DashboardLayout>
    );
}
