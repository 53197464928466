import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import TitleAndTwoButtons from "../../components/SectionHeaders/TitleAndTwoButton";
import { useShortLink } from "../../context/ShortLinkContext";
import DashboardLayout from "../../layouts/Dashboard";
import ShortLinkForm from "./Form";

export default function AddShortLink() {
    const navigate = useNavigate();
    const { createShortLink } = useShortLink();

    const [shortLink, setShortLink] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    // TODO: setup notification toast, create HOC
    const onSubmit = async () => {
        let createObject = {
            ...shortLink,
        };

        const result = await createShortLink(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/short-links");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <TitleAndTwoButtons title="New short link" />

            <ShortLinkForm
                shortLink={shortLink}
                onNameChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        name: e.target.value,
                    });
                }}
                onOriginalURLChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        originalURL: e.target.value,
                    });
                }}
                onShortCodeChange={(e) => {
                    setShortLink({
                        ...shortLink,
                        shortCode: e.target.value,
                    });
                }}
                submitButtonText="Create"
                onClick={onSubmit}
                onDiscard={() => navigate("/short-links")}
            />
        </DashboardLayout>
    );
}
