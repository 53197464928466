import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import zaagLogo from "../../lib/images/zaag.png";
import { classNames } from "../../utils";
import { RenderLink, RenderParentItem } from "./Common";

export default function Nav({ navigation }) {
    const [openIndex, setOpenIndex] = useState(null);

    const handleDiscloserChange = (idx) => {
        openIndex &&
            openIndex !== idx &&
            document.getElementById(openIndex).click();

        idx === openIndex ? setOpenIndex(null) : setOpenIndex(idx);
    };

    return (
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center justify-center flex-shrink-0 px-4 py-5">
                    <Link to="/">
                        <img
                            className="w-40"
                            src={zaagLogo}
                            alt="ZAAG Systems Limited Portal"
                        />
                    </Link>
                </div>
                <nav className="mt-5 flex-1 px-3 pl-3 space-y-2 overflow-hidden">
                    {navigation.map(
                        (item, idx) =>
                            !item.hidden && (
                                <Disclosure
                                    open={idx === openIndex}
                                    key={item.name}
                                >
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button
                                                className={classNames(
                                                    "w-full flex items-center justify-between ",
                                                    "bg-gray text-white-800 rounded-md  text-sm font-medium",
                                                    item.current
                                                        ? "bg-gray-900 text-white"
                                                        : "text-gray-300",
                                                    "hover:bg-gray-700",
                                                    "border-2 border-opacity-20 border-gray-100"
                                                )}
                                                id={idx}
                                                onClick={() => {
                                                    handleDiscloserChange(idx);
                                                }}
                                            >
                                                {item.href ? (
                                                    <RenderLink item={item} />
                                                ) : (
                                                    <RenderParentItem
                                                        item={item}
                                                    />
                                                )}

                                                {item.children && (
                                                    <ChevronUpIcon
                                                        className={`${
                                                            !open
                                                                ? "rotate-180 transform"
                                                                : ""
                                                        } h-4 w-4 text-gray-500`}
                                                    />
                                                )}
                                            </Disclosure.Button>

                                            {item.children && (
                                                <Disclosure.Panel
                                                    className={classNames(
                                                        "border-b-2 border-opacity-5 border-gray-50 overflow-hidden",
                                                        "bg-gray-700 text-gray-300"
                                                    )}
                                                >
                                                    {open &&
                                                        item.children.map(
                                                            (child) => (
                                                                <React.Fragment
                                                                    key={
                                                                        child.name
                                                                    }
                                                                >
                                                                    {!child.hidden && (
                                                                        <RenderLink
                                                                            item={
                                                                                child
                                                                            }
                                                                            isChild={
                                                                                true
                                                                            }
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </Disclosure.Panel>
                                            )}
                                        </>
                                    )}
                                </Disclosure>
                            )
                    )}
                </nav>
            </div>
        </div>
    );
}
