import axios from "axios";
import { TESTIMONIAL } from "../../lib/slug";

export const list = async (token, type) => {
    try {
        const response = await axios.get(TESTIMONIAL + "/all/" + type, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
