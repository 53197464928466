import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PagingWithNumber from "../../components/Pagination/PagingWithNumber";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import List from "./List";

export default function Users() {
    const navigate = useNavigate();
    const { getUsers, users, deleteUser, currentPage, totalPages, pageList } =
        useUser();

    const [searchParams] = useSearchParams();
    const { user } = useAuth();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [, setShowToast] = React.useState(false);
    // const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        getUsers(searchParams.get("p") || 1);
    }, [searchParams, getUsers]);

    const onDelete = async (id) => {
        const res = await deleteUser(id);

        getUsers();

        setToastMessage(res.message);
        setShowToast(true);
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {users && (
                <>
                    <List
                        requiredPermissions={{
                            create: Permission.CREATE_USER,
                            update: Permission.UPDATE_USER,
                            delete: Permission.DELETE_USER,
                        }}
                        userPermissions={user?.role?.permissions}
                        title={"Users"}
                        description={"List of all users"}
                        addButton={"Add user"}
                        addButtonOnClick={() => navigate("/users/add")}
                        deleteButtonOnClick={onDelete}
                        tableHeads={["Name", "Username", "Role"]}
                        tableData={users}
                    />

                    {totalPages > 1 && (
                        <PagingWithNumber
                            linkPrefix={"/users"}
                            pageList={pageList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPrevious={() =>
                                navigate("/users?p=" + (currentPage - 1))
                            }
                            onNext={() =>
                                navigate("/users?p=" + (currentPage + 1))
                            }
                        />
                    )}
                </>
            )}
        </DashboardLayout>
    );
}
