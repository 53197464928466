const {
    AdjustmentsVerticalIcon,
    LockClosedIcon,
} = require("@heroicons/react/24/outline");
const { default: Settings } = require("../../pages/Settings");

module.exports = (Permission) => [
    {
        name: "Settings",
        // href: "/settings",
        icon: AdjustmentsVerticalIcon,
        current: false,
        component: Settings,
        hidden: false,
        permissions: [Permission.ALL],
        children: [
            {
                name: "Change Password",
                href: "/settings/change-password",
                icon: LockClosedIcon,
                current: false,
                component: Settings,
                hidden: false,
                permissions: [Permission.ALL],
            },
        ],
    },
];
