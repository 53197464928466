import { classNames } from "../../utils";

export default function BasicPanel({ children, className }) {
    return (
        <div
            className={classNames(
                "overflow-hidden rounded-lg bg-white shadow",
                className
            )}
        >
            <div className="px-4 py-5 sm:p-6">{children}</div>
        </div>
    );
}
