import React from "react";
import { classNames } from "../../utils";

export default function NonTargetedModal({ children, isBgBlur }) {
    return (
        <div
            className={classNames(
                "fixed left-0 right-0 top-0 bottom-0 z-10 flex justify-center items-center",
                isBgBlur && "backdrop-blur-sm bg-black/30"
            )}
        >
            {children}
        </div>
    );
}
