import axios from "axios";
import { USER } from "../../lib/slug";

export const update = async (token, id, data) => {
    try {
        const response = await axios.patch(USER, data, {
            params: {
                id: id,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
