import jsCookie from "js-cookie";
import React from "react";
import { productHandler } from "../handler/product";

const ProductContext = React.createContext();

export const useProduct = () => React.useContext(ProductContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function ProductProvider({ children }) {
    const [products, setProducts] = React.useState(null);

    const getAllProduct = React.useCallback(async () => {
        const result = await productHandler.list(token);

        setProducts(result);

        return result;
    }, []);

    const getProductById = async (id) => {
        const result = await productHandler.get(token, id);

        return result;
    };

    const updateProduct = async (id, data) => {
        const result = await productHandler.update(token, id, data);

        return result;
    };

    const createProduct = async (data) => {
        const result = await productHandler.create(token, data);

        return result;
    };

    const deleteProduct = async (id) => {
        const result = await productHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await productHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await productHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        products,
        getAllProduct,
        getProductById,
        updateProduct,
        createProduct,
        deleteProduct,
        publish,
        unPublish,
    };

    return (
        <ProductContext.Provider value={contextValue}>
            {children}
        </ProductContext.Provider>
    );
}
