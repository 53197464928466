import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexBasisEven from "../../components/Design/OneLiner/FlexBasisEven";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";
import { SHORT_LINK_HOST } from "../../lib/slug";

export default function TestimonialForm({
    shortLink,
    onNameChange,
    onOriginalURLChange,
    onShortCodeChange,
    onClick,
    submitButtonText,
    sectionHeader,
    onDiscard,
    disableLongURLEdit = false,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="Name"
                                label="Name"
                                htmlFor="Name"
                                showLabel={true}
                                ariaDescribeBy="Name-helper-text"
                                hint="Name of the short link"
                            >
                                <TextareaInput
                                    htmlFor="Name"
                                    value={shortLink?.name || ""}
                                    onChange={onNameChange}
                                    placeholder="Enter Name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="originalURL"
                                label="Original URL"
                                htmlFor="originalURL"
                                showLabel={true}
                                ariaDescribeBy="originalURL-helper-text"
                                hint="Original URL of the short link"
                            >
                                <TextareaInput
                                    htmlFor="originalURL"
                                    value={shortLink?.originalURL || ""}
                                    onChange={onOriginalURLChange}
                                    disabled={disableLongURLEdit}
                                    rows={3}
                                    className={
                                        disableLongURLEdit
                                            ? "bg-gray-100 cursor-not-allowed"
                                            : ""
                                    }
                                    placeholder="Enter Original URL"
                                />
                            </LabelAndHelperWrapper>

                            <FlexBasisEven
                                kids={[
                                    <LabelAndHelperWrapper
                                        key="host"
                                        htmlFor="host"
                                        label="Host"
                                        showLabel={true}
                                        ariaDescribeBy="host-helper-text"
                                    >
                                        <TextareaInput
                                            className="bg-gray-100 cursor-not-allowed"
                                            disabled
                                            htmlFor="host"
                                            value={SHORT_LINK_HOST}
                                        />
                                    </LabelAndHelperWrapper>,

                                    <LabelAndHelperWrapper
                                        key="shortCode"
                                        htmlFor="shortCode"
                                        label="Short Code"
                                        showLabel={true}
                                        ariaDescribeBy="shortCode-helper-text"
                                        hint="Will be auto generated if left empty"
                                    >
                                        <TextareaInput
                                            htmlFor="shortCode"
                                            value={shortLink?.shortCode || ""}
                                            onChange={onShortCodeChange}
                                            placeholder="Enter Short Code"
                                        />
                                    </LabelAndHelperWrapper>,
                                ]}
                            />
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
