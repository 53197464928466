export const jobTypes = ["FULL_TIME", "PART_TIME", "CONTRACT", "INTERNSHIP"];
export const jobNatures = ["REMOTE", "ON_SITE", "HYBRID"];
export const jobStatuses = [true, false];

export const ZAAG_LOGO_FOR_LOGIN_PAGE =
    "https://s3.cdn.zaagsys.com/zaagsys.png";

export const blogCategories = ["Mobile Apps", "Frontend", "Backend"];
export const blogVisibilities = ["PUBLIC", "PRIVATE"];
export const blogStatuses = ["PUBLISHED", "UNPUBLISHED"];
export const defaultBlogCategory = "Uncategorized";
