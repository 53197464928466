import noun from "pluralize";

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function delayRedirect(delay, url) {
    setTimeout(() => {
        window.location.href = url;
    }, delay);
}

export function singularize(str) {
    return noun.singular(str);
}

export function pluralize(str) {
    return noun.plural(str);
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getSlugFromSentence = ({
    sentence,
    split = " ",
    separator = "-",
    maxLength = 10,
}) => {
    const words = sentence.split(split);

    let slug = words.slice(0, maxLength).join(separator).toLowerCase();

    // remove all non-alphanumeric characters
    slug = slug.replace(/[^a-z0-9-]/g, "");

    // remove multiple dashes
    slug = slug.replace(/-+/g, "-");

    // remove dashes from start and end
    slug = slug.replace(/^-+/, "").replace(/-+$/, "");

    return slug;
};

/**
 *
 * @param {string} str The string to operate on
 * @param {number} index The index to check in the string
 * @param {string} char The character to check for
 * @returns {string} _str The string with the char added at the index
 */
export const setStartingSlashUnlessExists = ({
    str,
    index = 0,
    char = "/",
}) => {
    let _str = str;

    _str = _str.charAt(index) !== char && "/" + _str;

    return _str;
};

/**
 * Formats datetime string to a human readable format
 * @param {string} date The datetime string to be formatted
 * @returns {string} The formatted date string
 */
export const formateDate = (date) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString("US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

/**
 * Converts a string to a sentence case
 * @param {string} str The string to be converted to sentence case
 * @returns {string} The converted string
 */
export let convertToSentenceCase = (str) => {
    return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export let convertToTitleCase = (str) => {
    return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(" ");
};

/**
 * Removes a character from a string
 * @param {string} str The string to be remove character from
 * @param {string} char The character to remove
 * @param {string} replacer The character to replace the other character
 * @returns {string} The string without the character
 */
export const replaceChar = (str, char, replacer = " ") => {
    return str.replace(char, replacer);
};

/**
 * Returns full image url with prefix and key
 * @param {string} prefix URL to be added before the key
 * @param {string} key Image key returned from S3
 * @returns {string} The full URL to the image
 */
export const generateImageURL = (prefix, key) => {
    return `${prefix}/?key=${key}`;
};

export const showNCharacters = (str, n) => {
    return str.length > n ? str.slice(0, n) + "..." : str;
};

export const codeToJSONString = (codeSnippet) => {
    const escapedCodeSnippet = codeSnippet
        .replace(/\\/g, "\\\\")
        .replace(/"/g, '\\"')
        .replace(/\n/g, "\\n");

    return `{code: "${escapedCodeSnippet}"}`;
};

export const JSONStringToCode = (jsonString) => {
    console.log(jsonString);

    const parsedJson = JSON.parse(jsonString);

    console.log(parsedJson, typeof JSON.parse(jsonString));

    return parsedJson.code.replace(/\\n/g, "\n");
};

/**
 * Deletes properties from object
 * @param {object} object The object from where the properties will be deleted
 * @param {array} props List of properties to be deleted
 * @returns {object} The clean object after deleting the props
 */
export const deletePropertiesFromObject = (object, props) => {
    return props.reduce(
        (newObj, prop) => {
            if (newObj.hasOwnProperty(prop)) {
                delete newObj[prop];
            }
            return newObj;
        },
        { ...object }
    );
};
