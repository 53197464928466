export default function LabelAndHelperWrapper({
    children,
    label,
    htmlFor,
    showLabel = false,
    ariaDescribeBy,
    hint,
    className,
}) {
    return (
        <div>
            <div className="flex justify-between">
                <label
                    htmlFor={htmlFor}
                    className={`${
                        showLabel !== true && "sr-only"
                    } text-sm leading-7 font-medium text-gray-700`}
                >
                    {label}
                </label>

                <span className="text-sm text-gray-500" id={ariaDescribeBy}>
                    {hint}
                </span>
            </div>

            <div
                className={`border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 ${
                    className ? className : null
                }`}
            >
                {children}
            </div>
        </div>
    );
}
