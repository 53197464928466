const {
    PencilSquareIcon,
    CircleStackIcon,
    ClipboardDocumentCheckIcon,
    PhotoIcon,
    CheckBadgeIcon,
    DocumentCheckIcon,
    CubeIcon,
} = require("@heroicons/react/24/outline");
const { default: Blogs } = require("../../pages/Blog");
const { default: BlogCategories } = require("../../pages/Blog/Category");
const { default: CaseStudy } = require("../../pages/CaseStudy");
const { default: MediaGallery } = require("../../pages/MediaGallery");
const { default: Badge } = require("../../pages/Badge");
const { default: Testimonial } = require("../../pages/Testimonial");
const { default: Product } = require("../../pages/Product");

module.exports = (Permission) => [
    {
        name: "Content Hub",
        icon: CircleStackIcon,
        current: false,

        hidden: false,
        permissions: [Permission.READ_BLOG],
        children: [
            {
                name: "Badges",
                href: "/badges",
                icon: CheckBadgeIcon,
                current: false,
                component: Badge,
                hidden: false,
                permissions: [Permission.READ_BADGE],
            },
            {
                name: "Blogs",
                href: "/blogs",
                icon: PencilSquareIcon,
                current: false,
                component: Blogs,
                hidden: false,
                permissions: [Permission.READ_BLOG],
            },
            {
                name: "Blog Categories",
                href: "/blogs/categories",
                icon: CircleStackIcon,
                current: false,
                component: BlogCategories,
                hidden: false,
                permissions: [Permission.READ_BLOG_CATEGORY],
            },
            {
                name: "Case Studies",
                href: "/case-studies",
                icon: ClipboardDocumentCheckIcon,
                current: false,
                component: CaseStudy,
                hidden: false,
                permissions: [Permission.READ_CASE_STUDIES],
            },
            {
                name: "Media Library",
                icon: PhotoIcon,
                current: false,
                component: MediaGallery,
                hidden: true,
                href: "/gallery",
                permissions: [Permission.ALL],
                children: [],
            },
            {
                name: "Products",
                icon: CubeIcon,
                current: false,
                component: Product,
                hidden: false,
                href: "/products",
                permissions: [Permission.READ_PRODUCT],
                children: [],
            },
            {
                name: "Testimonial",
                href: "/testimonials",
                icon: DocumentCheckIcon,
                current: false,
                component: Testimonial,
                hidden: false,
                permissions: [Permission.READ_TESTIMONIAL],
            },
        ],
    },
];
