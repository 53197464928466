import axios from "axios";
import { GET_ALL_PERMISSIONS } from "../../lib/slug";

export const listPermissions = async (token, id) => {
    try {
        const response = await axios.get(GET_ALL_PERMISSIONS, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
