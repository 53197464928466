import { Bars4Icon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useMenu } from "../context/MenuContext";
import { useFindPath } from "../hooks/useFindPath";
import notFoundImage from "../lib/images/image-not-found.webp";
import { IMAGES } from "../lib/slug";
import { generateImageURL } from "../utils";

function renderUserProfile(user) {
    return (
        <div className="flex items-center justify-start">
            <div>
                <img
                    className="inline-block w-10 h-10 rounded-full object-cover"
                    src={
                        user?.profileImage
                            ? generateImageURL(IMAGES, user?.profileImage)
                            : notFoundImage
                    }
                    alt={user?.name}
                />
            </div>
            <div className="ml-3">
                <p className="text-sm font-medium text-gray-700">
                    {user?.name}
                </p>

                <Link to="/profile">
                    <p className="text-xs font-medium text-indigo-500 hover:text-indigo-400 active:text-indigo-700 group-hover:text-white">
                        View profile
                    </p>
                </Link>
            </div>
        </div>
    );
}

export default function DashboardLayout({ children, breadCrumbs, className }) {
    const { setSidebarOpen, user } = useMenu();
    const path = useFindPath();

    const memoizedUserProfile = useMemo(() => {
        return user && path !== "/profile" ? renderUserProfile(user) : null;
    }, [user, path]);

    return (
        <div className={`${className ? className : null}`}>
            <div>
                <div className="md:pl-64 flex flex-col flex-1">
                    {/* Menu button for mobile */}
                    <div className="flex item-center justify-between md:justify-end sticky top-0 z-10 px-4 md:px-8 py-1 sm:py-4 bg-gray-100 md:border-b md:bg-gray-50 md:shadow-sm">
                        <div className="md:hidden">
                            <button
                                type="button"
                                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars4Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>

                        {memoizedUserProfile}
                    </div>

                    {/* Actual Content */}
                    <main className="flex-1">
                        <div className="py-6">
                            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                                {/* <BreadCrumb
                                        pages={breadCrumbs ? breadCrumbs : null}
                                    /> */}

                                {children}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}
