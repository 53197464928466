export default function CardWithHeaderFooter({
    headerItems,
    body,
    footerItems,
    className,
}) {
    return (
        <div
            className={`bg-white shadow rounded-lg divide-y divide-gray-200 ${
                className ? className : null
            }`}
        >
            <div className="px-4 py-5 sm:px-6">
                {headerItems ? headerItems : <></>}
            </div>
            <div className="px-4 py-5 sm:p-6">{body ? body : <></>}</div>
            <div className="px-4 py-4 sm:px-6">
                {footerItems ? footerItems : <></>}
            </div>
        </div>
    );
}
