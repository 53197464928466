import axios from "axios";
import { TESTIMONIAL } from "../../lib/slug";

export const get = async (token, id, type) => {
    try {
        const response = await axios.get(TESTIMONIAL + "/all/" + type, {
            params: {
                id: id,
            },

            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
