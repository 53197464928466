import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";

export default function PagingWithNumber({
    pageList,
    currentPage,
    onPrevious,
    onNext,
    linkPrefix,
}) {
    const currentPageTextStyles = "border-indigo-500 text-indigo-600";
    const regularPageTextStyles =
        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";

    const renderPages = (pageList) => {
        const MAX_VISIBLE_PAGES = 5;
        const totalPages = pageList.length;
        const visiblePages = [];

        if (totalPages <= MAX_VISIBLE_PAGES) {
            visiblePages.push(...pageList);
        } else {
            const startPage = Math.max(currentPage - 2, 1);
            const endPage = Math.min(currentPage + 2, totalPages);

            if (startPage > 1) {
                visiblePages.push(1);
                if (startPage > 2) {
                    visiblePages.push("...");
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                visiblePages.push(i);
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    visiblePages.push("...");
                }
                visiblePages.push(totalPages);
            }
        }

        return visiblePages.map((page) => {
            const isCurrentPage = page === currentPage;
            const classNames = `inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                isCurrentPage ? currentPageTextStyles : regularPageTextStyles
            }`;

            return (
                <Link
                    to={linkPrefix + "?p=" + page}
                    key={page}
                    className={classNames}
                >
                    {page}
                </Link>
            );
        });
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <button
                        onClick={onPrevious}
                        disabled={currentPage === 1}
                        className={`inline-flex ${
                            currentPage === 1
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        } items-center border-t-2  pt-4 pr-1 text-sm font-medium ${regularPageTextStyles}`}
                    >
                        <ArrowLongLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        Previous
                    </button>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    {currentPage > 0 && currentPage <= pageList.length && (
                        <>
                            {renderPages(
                                pageList.slice(
                                    Math.max(0, currentPage - 3),
                                    Math.min(currentPage + 2, pageList.length)
                                )
                            )}
                        </>
                    )}
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <button
                        onClick={onNext}
                        disabled={currentPage === pageList.length}
                        className={`inline-flex first-letter:
                        ${
                            currentPage === pageList.length
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        }
                        items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700`}
                    >
                        Next
                        <ArrowLongRightIcon
                            className="ml-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </nav>
        </div>
    );
}
