import jsCookie from "js-cookie";
import React from "react";
import { userHandler } from "../handler/user";
const UserContext = React.createContext();

export const useUser = () => React.useContext(UserContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function UserProvider({ children }) {
    const [users, setUsers] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [limit] = React.useState(10);
    const [pageList, setPageList] = React.useState([]);

    const getUsers = React.useCallback(
        async (page) => {
            const result = await userHandler.list(token, {
                page: page,
                limit: limit,
            });

            setUsers(result.data);
            setTotalPages(result.totalPage);
            setCurrentPage(parseInt(result.page));
            setPageList(
                Array.from({ length: result.totalPage }, (v, k) => k + 1)
            );

            return result;
        },
        [limit]
    );

    const deleteUser = async (id) => {
        const result = await userHandler.delete(token, id);

        return result;
    };

    const getUserById = React.useCallback(async (id) => {
        const result = await userHandler.get(token, id);

        return result;
    }, []);

    const updateUser = async (id, data) => {
        const result = await userHandler.update(token, id, data);

        return result;
    };

    const createUser = async (data) => {
        const result = await userHandler.create(token, data);

        return result;
    };

    const changePassword = async (data) => {
        const result = await userHandler.changePassword(token, data);

        return result;
    };

    const updateProfile = async (data) => {
        const result = await userHandler.updateProfile(token, data);

        return result;
    };

    const contextValue = {
        users,
        getUsers,
        deleteUser,
        getUserById,
        updateUser,
        createUser,
        currentPage,
        totalPages,
        pageList,
        changePassword,
        updateProfile,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
}
