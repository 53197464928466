import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import SimpleDropdown from "../../components/Dropdown/Simple";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";

export default function InjectScriptForm({
    injectScript,
    scriptTypes,
    onNameChange,
    onIDChange,
    onClick,
    onTypeChange,
    onContentChange,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="name"
                                label="Name"
                                htmlFor="name"
                                showLabel={true}
                                ariaDescribeBy="name-helper-text"
                                hint="Name of the script"
                            >
                                <TextareaInput
                                    htmlFor="name"
                                    value={injectScript?.name || ""}
                                    onChange={onNameChange}
                                    placeholder="Enter name"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="id"
                                label="ID"
                                htmlFor="id"
                                showLabel={true}
                                ariaDescribeBy="id-helper-text"
                                hint="ID of the script"
                            >
                                <TextareaInput
                                    htmlFor="id"
                                    value={injectScript?.id || ""}
                                    onChange={onIDChange}
                                    placeholder="Enter id"
                                />
                            </LabelAndHelperWrapper>

                            <SimpleDropdown
                                label="Type"
                                defaultValue="Select type"
                                options={scriptTypes}
                                selected={injectScript?.type || "Link"}
                                onSelect={onTypeChange}
                            />

                            <LabelAndHelperWrapper
                                key="content"
                                label="Content"
                                htmlFor="content"
                                showLabel={true}
                                ariaDescribeBy="content-helper-text"
                                hint="Content of the script"
                            >
                                <TextareaInput
                                    htmlFor="content"
                                    value={injectScript?.content || ""}
                                    onChange={onContentChange}
                                    placeholder="Enter content"
                                    rows={5}
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
