import jsCookie from "js-cookie";
import React from "react";
import { officeLocationHandler } from "../handler/officeLocation";

const OfficeLocationContext = React.createContext();

export const useOfficeLocation = () => React.useContext(OfficeLocationContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function OfficeLocationProvider({ children }) {
    const [officeLocations, setOfficeLocations] = React.useState(null);

    const getAllOfficeLocation = React.useCallback(async () => {
        const result = await officeLocationHandler.list(token);

        setOfficeLocations(result);

        return result;
    }, []);

    const getOfficeLocationById = async (id) => {
        const result = await officeLocationHandler.get(token, id);

        return result;
    };

    const updateOfficeLocation = async (id, data) => {
        const result = await officeLocationHandler.update(token, id, data);

        return result;
    };

    const createOfficeLocation = async (data) => {
        const result = await officeLocationHandler.create(token, data);

        return result;
    };

    const deleteOfficeLocation = async (id) => {
        const result = await officeLocationHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await officeLocationHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await officeLocationHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        officeLocations,
        getAllOfficeLocation,
        getOfficeLocationById,
        updateOfficeLocation,
        createOfficeLocation,
        deleteOfficeLocation,
        publish,
        unPublish,
    };

    return (
        <OfficeLocationContext.Provider value={contextValue}>
            {children}
        </OfficeLocationContext.Provider>
    );
}
