import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BlockCenteredModal from "../../../components/Modal/BlockCenter";
import NewItem from "../../../components/NewItem";
import PagingWithNumber from "../../../components/Pagination/PagingWithNumber";
import { useAuth } from "../../../context/AuthContext";
import { useBlogCategory } from "../../../context/BlogCategoryContext";
import DashboardLayout from "../../../layouts/Dashboard";
import { Permission } from "../../../utils/permissionStrings";
import List from "./List";

export default function BlogCategories() {
    const navigate = useNavigate();
    const {
        getBlogCategories,
        blogCategories,
        deleteBlogCategory,
        currentPage,
        totalPages,
        pageList,
    } = useBlogCategory();

    const [searchParams] = useSearchParams();
    const { user } = useAuth();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [, setShowToast] = React.useState(false);
    // const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
        getBlogCategories(searchParams.get("p") || 1);
    }, [searchParams, getBlogCategories]);

    const onDelete = async (id) => {
        const res = await deleteBlogCategory(id);

        getBlogCategories();

        setToastMessage(res.message);
        setShowToast(true);
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {!blogCategories ? (
                <BlockCenteredModal>
                    <NewItem
                        name="post"
                        onClick={() => navigate("/blogs/categories/add")}
                    />
                </BlockCenteredModal>
            ) : (
                <>
                    <List
                        requiredPermissions={{
                            create: Permission.CREATE_BLOG_CATEGORY,
                            update: Permission.UPDATE_BLOG_CATEGORY,
                            delete: Permission.DELETE_BLOG_CATEGORY,
                        }}
                        userPermissions={user?.role?.permissions}
                        title={"Blog Categories"}
                        description={"List of all blog categories"}
                        addButton={"Add category"}
                        addButtonOnClick={() =>
                            navigate("/blogs/categories/add")
                        }
                        deleteButtonOnClick={onDelete}
                        tableHeads={["Name", "Slug"]}
                        tableData={blogCategories}
                    />

                    {totalPages > 1 && (
                        <PagingWithNumber
                            linkPrefix={"/blogs/categories"}
                            pageList={pageList}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPrevious={() =>
                                navigate(
                                    "/blogs/categories?p=" + (currentPage - 1)
                                )
                            }
                            onNext={() =>
                                navigate(
                                    "/blogs/categories?p=" + (currentPage + 1)
                                )
                            }
                        />
                    )}
                </>
            )}
        </DashboardLayout>
    );
}
