import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useAuth } from "../../context/AuthContext";
import { useShortLink } from "../../context/ShortLinkContext";
import DashboardLayout from "../../layouts/Dashboard";
import { Permission } from "../../utils/permissionStrings";
import ShortLinkList from "./List";

export default function URLShortner() {
    const {
        apiResponse,
        getAllShortLink,
        deleteShortLink,
        publish,
        unPublish,
        getShortLinkById,
    } = useShortLink();
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const { user } = useAuth();

    React.useEffect(() => {
        getAllShortLink();
    }, [getAllShortLink]);

    const onDeleteShortLink = async (id) => {
        const res = await deleteShortLink(id);

        getAllShortLink();

        setToastMessage(res.message);
        setShowToast(true);
    };

    const onPublish = async (id) => {
        const result = await publish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllShortLink();
    };

    const onUnPublish = async (id) => {
        const result = await unPublish(id);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
        getAllShortLink();
    };

    // TODO: fix timeout, its rendering and then navigating promptly
    React.useEffect(() => {
        if (toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            {apiResponse && (
                <ShortLinkList
                    requiredPermissions={{
                        create: Permission.CREATE_SHORT_LINK,
                        update: Permission.UPDATE_SHORT_LINK,
                        delete: Permission.DELETE_SHORT_LINK,
                        publish: Permission.PUBLISH_SHORT_LINK,
                        unPublish: Permission.UNPUBLISH_SHORT_LINK,
                    }}
                    userPermissions={user?.role?.permissions}
                    title={"URLShortner"}
                    description={"List of all short links"}
                    addButton={"Short new link"}
                    addButtonOnClick={() => navigate("/short-links/add")}
                    onEditButtonClick={(id) =>
                        navigate(`/short-links/edit/${id}`)
                    }
                    deleteButtonOnClick={onDeleteShortLink}
                    tableHeads={["Name", "Status"]}
                    tableData={apiResponse.shortlinks}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    getInfo={getShortLinkById}
                />
            )}
        </DashboardLayout>
    );
}
