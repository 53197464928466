import React from "react";
import ActionConfirmer from "../../components/ActionConfirmer";
import PrimaryButton from "../../components/Button/Primary";
import SecondaryButton from "../../components/Button/Secondary";
import CardWithHeaderFooter from "../../components/Card/WithHeaderFooter";
import FlexOneLineJustifyBetween from "../../components/Design/OneLiner/FlexJustifyBetween";
import FlexOneLineJustifyEnd from "../../components/Design/OneLiner/FlexJustifyEnd";
import LabelAndHelperWrapper from "../../components/Input/LabelAndHelperWrapper";
import TextareaInput from "../../components/Input/Textarea";

export default function OfficeLocationForm({
    officeLocation,
    onCountryChange,
    onPositionChange,
    onAddressLineChange,
    onAddressAdditionalLineChange,
    onAddressCityChange,
    onAddressStateChange,
    onAddressZipChange,
    onGoogleMapURLChange,
    onEmailsChange,
    onPhonesChange,
    onClick,
    submitButtonText,
    sectionHeader,
    onDiscard,
}) {
    const [isCreateModalOpen] = React.useState(true);
    const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

    return (
        <>
            {isCreateModalOpen && (
                <CardWithHeaderFooter
                    className="w-full"
                    headerItems={
                        <h1 className="font-medium">{sectionHeader}</h1>
                    }
                    body={
                        <div className="space-y-5">
                            <LabelAndHelperWrapper
                                key="country"
                                label="Country"
                                htmlFor="country"
                                showLabel={true}
                                ariaDescribeBy="country-helper-text"
                                hint="Country of the office"
                            >
                                <TextareaInput
                                    htmlFor="country"
                                    value={officeLocation?.country || ""}
                                    onChange={onCountryChange}
                                    placeholder="Enter country"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="addressLine"
                                label="Address line"
                                htmlFor="address line"
                                showLabel={true}
                                ariaDescribeBy="address line-helper-text"
                                hint="Address line of the office location"
                            >
                                <TextareaInput
                                    htmlFor="address line"
                                    value={officeLocation?.address?.line || ""}
                                    onChange={onAddressLineChange}
                                    placeholder="Enter address line"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="additionalAddressLine"
                                label="Additional address line"
                                htmlFor="additionalAddressLine"
                                showLabel={true}
                                ariaDescribeBy="additionalAddressLine-helper-text"
                                hint="Additional address line of the script"
                            >
                                <TextareaInput
                                    htmlFor="additionalAddressLine"
                                    value={
                                        officeLocation?.address
                                            ?.additionalLine || ""
                                    }
                                    onChange={onAddressAdditionalLineChange}
                                    onBlur={onAddressAdditionalLineChange}
                                    placeholder="Enter additional address line"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="city"
                                label="City"
                                htmlFor="city"
                                showLabel={true}
                                ariaDescribeBy="city-helper-text"
                                hint="City of the office"
                            >
                                <TextareaInput
                                    htmlFor="city"
                                    value={officeLocation?.address?.city || ""}
                                    onChange={onAddressCityChange}
                                    placeholder="Enter city"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="state"
                                label="State"
                                htmlFor="state"
                                showLabel={true}
                                ariaDescribeBy="state-helper-text"
                                hint="State of the office location"
                            >
                                <TextareaInput
                                    htmlFor="state"
                                    value={officeLocation?.address?.state || ""}
                                    onChange={onAddressStateChange}
                                    placeholder="Enter state"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="zip"
                                label="Zip code"
                                htmlFor="zip"
                                showLabel={true}
                                ariaDescribeBy="zip-helper-text"
                                hint="Zip code of the office location"
                            >
                                <TextareaInput
                                    htmlFor="zip"
                                    value={
                                        officeLocation?.address?.zipCode || ""
                                    }
                                    onChange={onAddressZipChange}
                                    placeholder="Enter zip"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="Google Map URL"
                                label="Google Map URL"
                                htmlFor="Google Map URL"
                                showLabel={true}
                                ariaDescribeBy="Google Map URL-helper-text"
                                hint="Google Map URL of the office location"
                            >
                                <TextareaInput
                                    htmlFor="Google Map URL"
                                    rows={3}
                                    value={
                                        officeLocation?.googleMapEmbedURL || ""
                                    }
                                    onChange={onGoogleMapURLChange}
                                    placeholder="Enter Google Map URL"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="Email"
                                label="Email"
                                htmlFor="Email"
                                showLabel={true}
                                ariaDescribeBy="Email-helper-text"
                                hint="Email of the office location"
                            >
                                <TextareaInput
                                    htmlFor="Email"
                                    value={officeLocation?.emails || ""}
                                    onChange={onEmailsChange}
                                    placeholder="Enter Email"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="Phone number"
                                label="Phone number"
                                htmlFor="Phone number"
                                showLabel={true}
                                ariaDescribeBy="Phone number-helper-text"
                                hint="Phone number of the office location"
                            >
                                <TextareaInput
                                    htmlFor="Phone number"
                                    value={officeLocation?.phones || ""}
                                    onChange={onPhonesChange}
                                    placeholder="Enter Phone number"
                                />
                            </LabelAndHelperWrapper>

                            <LabelAndHelperWrapper
                                key="position"
                                label="Position"
                                htmlFor="position"
                                showLabel={true}
                                ariaDescribeBy="position-helper-text"
                                hint="Position of the office location to be displayed in the website"
                            >
                                <TextareaInput
                                    htmlFor="position"
                                    value={officeLocation?.position || ""}
                                    onChange={onPositionChange}
                                    placeholder="Enter position"
                                />
                            </LabelAndHelperWrapper>
                        </div>
                    }
                    footerItems={
                        <FlexOneLineJustifyBetween>
                            <SecondaryButton
                                name="Discard"
                                onClick={() => {
                                    setIsCancelModalOpen(true);
                                }}
                            />
                            <FlexOneLineJustifyEnd>
                                <div className="space-x-5">
                                    <PrimaryButton
                                        name={submitButtonText || "Publish"}
                                        onClick={onClick}
                                    />
                                </div>
                            </FlexOneLineJustifyEnd>
                        </FlexOneLineJustifyBetween>
                    }
                />
            )}

            {isCancelModalOpen && (
                <ActionConfirmer
                    onConfirm={onDiscard}
                    onCancel={() => {
                        setIsCancelModalOpen(false);
                    }}
                />
            )}
        </>
    );
}
