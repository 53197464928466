import jsCookie from "js-cookie";
import React from "react";
import { shortLinkHandler } from "../handler/shortLink";

const ShortLinkContext = React.createContext();

export const useShortLink = () => React.useContext(ShortLinkContext);

const token = jsCookie.get(process.env.REACT_APP_JWT_TOKEN);

export function ShortLinkProvider({ children }) {
    const [apiResponse, setShortLinks] = React.useState(null);

    const getAllShortLink = React.useCallback(async () => {
        const result = await shortLinkHandler.list(token);

        setShortLinks(result);

        return result;
    }, []);

    const getShortLinkById = async (id) => {
        const result = await shortLinkHandler.get(token, id);

        return result;
    };

    const updateShortLink = async (id, data) => {
        const result = await shortLinkHandler.update(token, id, data);

        return result;
    };

    const createShortLink = async (data) => {
        const result = await shortLinkHandler.create(token, data);

        return result;
    };

    const deleteShortLink = async (id) => {
        const result = await shortLinkHandler.delete(token, id);

        return result;
    };

    const publish = async (id) => {
        const result = await shortLinkHandler.publish(token, id);

        return result;
    };

    const unPublish = async (id) => {
        const result = await shortLinkHandler.unPublish(token, id);

        return result;
    };

    const contextValue = {
        apiResponse,
        getAllShortLink,
        getShortLinkById,
        updateShortLink,
        createShortLink,
        deleteShortLink,
        publish,
        unPublish,
    };

    return (
        <ShortLinkContext.Provider value={contextValue}>
            {children}
        </ShortLinkContext.Provider>
    );
}
