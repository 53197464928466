import jsCookie from "js-cookie";
import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/Notification/Toast";
import { useBlog } from "../../context/BlogContext";
import { blogCategoryHandler } from "../../handler/blogCategory";
import { imageHandler } from "../../handler/image";
import DashboardLayout from "../../layouts/Dashboard";
import { defaultBlogCategory } from "../../lib";
import BlogForm from "./Form";

export default function CreateBlog() {
    const { createBlog } = useBlog();
    const navigate = useNavigate();

    const [blog, setBlog] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    const [categoriesNames, setCategoriesNames] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    const [toastMessage, setToastMessage] = React.useState(undefined);
    const [setToast, setShowToast] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const blogExcerptEditorRef = React.useRef();
    const blogContentEditorRef = React.useRef();

    const [image, setImage] = React.useState(null);
    const slugRef = React.useRef();

    const getBlogCategories = async () => {
        const categories = await blogCategoryHandler.list(
            jsCookie.get(process.env.REACT_APP_JWT_TOKEN),
            {
                limit: 100000,
                page: 1,
            }
        );

        setCategories(categories.data);
        setCategoriesNames(categories.data.map((category) => category.name));
    };

    React.useEffect(() => {
        getBlogCategories();
    }, []);

    React.useEffect(() => {
        const _selectedCategory = categories.find(
            (category) => category._id === blog?.category
        );

        setSelectedCategory(
            _selectedCategory ? _selectedCategory.name : defaultBlogCategory
        );
    }, [blog, categories]);

    const submitForm = async (e) => {
        e.preventDefault();

        const content = blogContentEditorRef.current.getContent();
        const excerpt = blogExcerptEditorRef.current.getContent();

        let createObject = { ...blog, content, excerpt };
        delete createObject._id;
        delete createObject.updatedAt;
        delete createObject.createdAt;
        delete createObject.share;
        delete createObject.count;

        createObject = {
            ...createObject,
            slug: createObject.slug || slugRef.current.value,
        };

        if (!createObject.category) {
            const category = categories.find(
                (category) => category.name === defaultBlogCategory
            );

            createObject = {
                ...createObject,
                category: category._id,
            };
        }

        if (image?.file) {
            const response = await imageHandler.uploadV2({
                image,
                folder: "blog",
            });

            createObject = response?.key && {
                ...createObject,
                featuredImage: response.key,
            };
        }

        const result = await createBlog(createObject);

        setToastMessage(result.message);

        result.status && result.status === 400
            ? setIsError(true)
            : setIsError(false);

        setShowToast(true);
    };

    React.useEffect(() => {
        if (setToast && toastMessage) {
            setTimeout(() => {
                setShowToast(false);
            }, 1500);
        }
    });

    React.useEffect(() => {
        if (!isError && toastMessage) {
            setTimeout(() => {
                navigate("/blogs");
            }, 2000);
        }
    });

    return (
        <DashboardLayout>
            {toastMessage && (
                <Toast
                    message={toastMessage}
                    show={setToast}
                    setShow={setShowToast}
                    isError={isError}
                />
            )}

            <BlogForm
                blog={blog}
                onClick={submitForm}
                categories={categoriesNames}
                selectedCategory={selectedCategory}
                submitButtonText="Create"
                sectionHeader="Add Blog"
                slugRef={slugRef}
                blogContentEditorRef={blogContentEditorRef}
                blogExcerptEditorRef={blogExcerptEditorRef}
                onCategoryChange={(e) => {
                    const category = categories.find(
                        (category) => category.name === e.target.value
                    );

                    setBlog({
                        ...blog,
                        category: category._id,
                    });
                }}
                onVisibilityChange={(e) => {
                    setBlog({ ...blog, visibility: e.target.value });
                }}
                onTitleChange={(e) => {
                    setBlog({ ...blog, title: e.target.value });
                }}
                onSlugChange={(e) => {
                    setBlog({ ...blog, slug: e.target.value });
                }}
                onTagsChange={(e) => {
                    setBlog({
                        ...blog,
                        tags: e.target.value
                            .split(",")
                            .map((tag) => tag.trim()),
                    });
                }}
                onFeaturedImageChange={(image, name) => {
                    setImage({ file: image, name: name });
                }}
                cleanFeaturedImage={() => {
                    setBlog({ ...blog, featuredImage: null });
                }}
                onDiscard={() => {
                    setTimeout(() => {
                        navigate(-1);
                    }, 500);
                }}
            />
        </DashboardLayout>
    );
}
