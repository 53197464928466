import axios from "axios";
import { CASE_STUDY } from "../../lib/slug";

export const create = async (token, data) => {
    try {
        const response = await axios.post(CASE_STUDY, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
