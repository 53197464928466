export const Permission = {
    ALL: "ALL",

    READ_DASHBOARD: "READ_DASHBOARD",
    READ_SETTINGS: "READ_SETTINGS",

    // Blog Module
    CREATE_BLOG: "CREATE_BLOG",
    READ_BLOG: "READ_BLOG",
    UPDATE_BLOG: "UPDATE_BLOG",
    DELETE_BLOG: "DELETE_BLOG",
    PERMANENT_DELETE_BLOG: "PERMANENT_DELETE_BLOG",
    PUBLISH_BLOG: "PUBLISH_BLOG",
    UNPUBLISH_BLOG: "UNPUBLISH_BLOG",
    SEE_BLOG_INFO: "SEE_BLOG_INFO",

    // Job Module
    CREATE_JOB: "CREATE_JOB",
    READ_JOB: "READ_JOB",
    UPDATE_JOB: "UPDATE_JOB",
    DELETE_JOB: "DELETE_JOB",
    PERMANENT_DELETE_JOB: "PERMANENT_DELETE_JOB",
    ACTIVATE_JOB: "ACTIVATE_JOB",
    DEACTIVATE_JOB: "DEACTIVATE_JOB",
    SEE_JOB_INFO: "SEE_JOB_INFO",

    // Role Module
    CREATE_ROLE: "CREATE_ROLE",
    READ_ROLE: "READ_ROLE",
    READ_ALL_ROLES: "READ_ALL_ROLES",
    UPDATE_ROLE: "UPDATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    PERMANENT_DELETE_ROLE: "PERMANENT_DELETE_ROLE",
    READ_ALL_PERMISSIONS: "READ_ALL_PERMISSIONS",

    // User Module
    CREATE_USER: "CREATE_USER",
    READ_USER: "READ_USER",
    UPDATE_USER: "UPDATE_USER",
    DELETE_USER: "DELETE_USER",
    PERMANENT_DELETE_USER: "PERMANENT_DELETE_USER",

    // BlogCategory Module
    CREATE_BLOG_CATEGORY: "CREATE_BLOG_CATEGORY",
    READ_BLOG_CATEGORY: "READ_BLOG_CATEGORY",
    UPDATE_BLOG_CATEGORY: "UPDATE_BLOG_CATEGORY",
    DELETE_BLOG_CATEGORY: "DELETE_BLOG_CATEGORY",
    PERMANENT_DELETE_BLOG_CATEGORY: "PERMANENT_DELETE_BLOG_CATEGORY",

    // JobCategory Module
    CREATE_JOB_CATEGORY: "CREATE_JOB_CATEGORY",
    READ_JOB_CATEGORY: "READ_JOB_CATEGORY",
    UPDATE_JOB_CATEGORY: "UPDATE_JOB_CATEGORY",
    DELETE_JOB_CATEGORY: "DELETE_JOB_CATEGORY",
    PERMANENT_DELETE_JOB_CATEGORY: "PERMANENT_DELETE_JOB_CATEGORY",

    // Admin Module
    READ_ACCESS_LOG: "READ_ACCESS_LOG",

    // HR Module
    RECOMMEND_LEAVE_APPLICATION: "RECOMMEND_LEAVE_APPLICATION",
    APPROVE_LEAVE_APPLICATION: "APPROVE_LEAVE_APPLICATION",
    READ_ALL_LEAVE_APPLICATIONS: "READ_ALL_LEAVE_APPLICATIONS",
    DELETE_LEAVE_APPLICATION: "DELETE_LEAVE_APPLICATION",
    CANCEL_LEAVE_APPLICATION: "CANCEL_LEAVE_APPLICATION",

    //Mail Module
    SEND_MAIL: "SEND_MAIL",
    READ_MAIL: "READ_MAIL",
    DELETE_MAIL: "DELETE_MAIL",
    PERMANENT_DELETE_MAIL: "PERMANENT_DELETE_MAIL",

    // SMTP Module
    CREATE_SMTP: "CREATE_SMTP",
    READ_SMTP: "READ_SMTP",
    UPDATE_SMTP: "UPDATE_SMTP",
    DELETE_SMTP: "DELETE_SMTP",
    PERMANENT_DELETE_SMTP: "PERMANENT_DELETE_SMTP",

    // Inject Script Module
    CREATE_INJECT_SCRIPT: "CREATE_INJECT_SCRIPT",
    READ_INJECT_SCRIPT: "READ_INJECT_SCRIPT",
    UPDATE_INJECT_SCRIPT: "UPDATE_INJECT_SCRIPT",
    DELETE_INJECT_SCRIPT: "DELETE_INJECT_SCRIPT",
    PERMANENT_DELETE_INJECT_SCRIPT: "PERMANENT_DELETE_INJECT_SCRIPT",
    PUBLISH_INJECT_SCRIPT: "PUBLISH_INJECT_SCRIPT",
    UNPUBLISH_INJECT_SCRIPT: "UNPUBLISH_INJECT_SCRIPT",

    // Comment module
    DELETE_COMMENT: "DELETE_COMMENT",
    PERMANENT_DELETE_COMMENT: "PERMANENT_DELETE_COMMENT",
    APPROVE_COMMENT: "APPROVE_COMMENT",
    REJECT_COMMENT: "REJECT_COMMENT",

    // Office Module
    CREATE_OFFICE: "CREATE_OFFICE",
    READ_OFFICE: "READ_OFFICE",
    UPDATE_OFFICE: "UPDATE_OFFICE",
    DELETE_OFFICE: "DELETE_OFFICE",
    PERMANENT_DELETE_OFFICE: "PERMANENT_DELETE_OFFICE",
    PUBLISH_OFFICE: "PUBLISH_OFFICE",
    UNPUBLISH_OFFICE: "UNPUBLISH_OFFICE",

    // Footer Links Module
    CREATE_FOOTER_LINKS: "CREATE_FOOTER_LINKS",
    READ_FOOTER_LINKS: "READ_FOOTER_LINKS",
    UPDATE_FOOTER_LINKS: "UPDATE_FOOTER_LINKS",
    DELETE_FOOTER_LINKS: "DELETE_FOOTER_LINKS",
    PERMANENT_DELETE_FOOTER_LINKS: "PERMANENT_DELETE_FOOTER_LINKS",
    PUBLISH_FOOTER_LINKS: "PUBLISH_FOOTER_LINKS",
    UNPUBLISH_FOOTER_LINKS: "UNPUBLISH_FOOTER_LINKS",

    // Badge Module
    CREATE_BADGE: "CREATE_BADGE",
    READ_BADGE: "READ_BADGE",
    UPDATE_BADGE: "UPDATE_BADGE",
    DELETE_BADGE: "DELETE_BADGE",
    PERMANENT_DELETE_BADGE: "PERMANENT_DELETE_BADGE",
    PUBLISH_BADGE: "PUBLISH_BADGE",
    UNPUBLISH_BADGE: "UNPUBLISH_BADGE",

    // Service link module
    CREATE_SERVICE_LINK: "CREATE_SERVICE_LINK",
    READ_SERVICE_LINK: "READ_SERVICE_LINK",
    UPDATE_SERVICE_LINK: "UPDATE_SERVICE_LINK",
    DELETE_SERVICE_LINK: "DELETE_SERVICE_LINK",
    PERMANENT_DELETE_SERVICE_LINK: "PERMANENT_DELETE_SERVICE_LINK",
    PUBLISH_SERVICE_LINK: "PUBLISH_SERVICE_LINK",
    UNPUBLISH_SERVICE_LINK: "UNPUBLISH_SERVICE_LINK",

    // Office Module
    CREATE_CASE_STUDIES: "CREATE_CASE_STUDIES",
    READ_CASE_STUDIES: "READ_CASE_STUDIES",
    UPDATE_CASE_STUDIES: "UPDATE_CASE_STUDIES",
    DELETE_CASE_STUDIES: "DELETE_CASE_STUDIES",
    PERMANENT_DELETE_CASE_STUDIES: "PERMANENT_DELETE_CASE_STUDIES",
    PUBLISH_CASE_STUDIES: "PUBLISH_CASE_STUDIES",
    UNPUBLISH_CASE_STUDIES: "UNPUBLISH_CASE_STUDIES",

    // Testimonial Module
    CREATE_TESTIMONIAL: "CREATE_TESTIMONIAL",
    READ_TESTIMONIAL: "READ_TESTIMONIAL",
    UPDATE_TESTIMONIAL: "UPDATE_TESTIMONIAL",
    DELETE_TESTIMONIAL: "DELETE_TESTIMONIAL",
    PERMANENT_DELETE_TESTIMONIAL: "PERMANENT_DELETE_TESTIMONIAL",
    PUBLISH_TESTIMONIAL: "PUBLISH_TESTIMONIAL",
    UNPUBLISH_TESTIMONIAL: "UNPUBLISH_TESTIMONIAL",

    // Short Link Module
    CREATE_SHORT_LINK: "CREATE_SHORT_LINK",
    READ_SHORT_LINK: "READ_SHORT_LINK",
    UPDATE_SHORT_LINK: "UPDATE_SHORT_LINK",
    DELETE_SHORT_LINK: "DELETE_SHORT_LINK",
    PERMANENT_DELETE_SHORT_LINK: "PERMANENT_DELETE_SHORT_LINK",
    PUBLISH_SHORT_LINK: "PUBLISH_SHORT_LINK",
    UNPUBLISH_SHORT_LINK: "UNPUBLISH_SHORT_LINK",

    // Product Module
    CREATE_PRODUCT: "CREATE_PRODUCT",
    READ_PRODUCT: "READ_PRODUCT",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    PERMANENT_DELETE_PRODUCT: "PERMANENT_DELETE_PRODUCT",
    PUBLISH_PRODUCT: "PUBLISH_PRODUCT",
    UNPUBLISH_PRODUCT: "UNPUBLISH_PRODUCT",
};
